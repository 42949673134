import {
    UPDATE_ACTIVE_LEFT_NAVBAR_OPTION,
    FETCH_ALL_PROJECTS_STARTED,
    FETCH_ALL_PROJECTS_FAILURE,
    FETCH_ALL_PROJECTS_SUCCESS,
    REMOVE_PROJECT_FROM_FAVOURITE,
    TOGGLE_PROJECT_FROM_FAVOURITE,
    ADD_NEW_PROJECT,
    RENAME_PROJECT,
    TOGGLE_DELETE_PROJECT,
    TOGGLE_ARCHIVE_PROJECT
} from "../actions/types";

export default (state = {loading: false, error: null, projects: {}}, action) => {
    switch (action.type){
        case UPDATE_ACTIVE_LEFT_NAVBAR_OPTION:
            return{
                ...state,
                activeLeftNavbarMenu: action.payload
            }
        case FETCH_ALL_PROJECTS_STARTED:
            return{
                ...state,
                loading: true,
                error: null
            }
        case FETCH_ALL_PROJECTS_SUCCESS:
            return{
                ...state,
                loading: false,
                error: null,
                projects: action.payload
            }
        case FETCH_ALL_PROJECTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case REMOVE_PROJECT_FROM_FAVOURITE: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: state.projects.items.map(project => project.id === action.payload ? {...project, isFavorite: false} : project)
                }
            }
        }
        case TOGGLE_ARCHIVE_PROJECT: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: state.projects.items.map(project => project.id === action.payload ? {...project, isArchived: !project.isArchived} : project)
                }
            }
        }
        case TOGGLE_DELETE_PROJECT: {

            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: state.projects.items.map(project => project.id === action.payload.id ? {...project, isDeleted: !action.payload.isDeleted} : project)
                }
            }
        }
        case RENAME_PROJECT: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: state.projects.items.map(project => project.id === action.payload.project_id ? {...project, name: action.payload.project_new_name} : project)
                }
            }
        }
        case TOGGLE_PROJECT_FROM_FAVOURITE: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    items: state.projects.items.map(project => project.id === action.payload ? {...project, isFavorite: !project.isFavorite} : project)
                }
            }
        }
        case ADD_NEW_PROJECT: {
            return{
                ...state,
                projects: {
                    ...state.projects,
                    items: [action.payload, ...state.projects.items]
                }
            }
        }
        default:
            return state;
    }
}