import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { toggleDeleteProject } from "../../actions";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

import axios from "../../axios";
import { NotifySuccess, NotifyError, NotifyInfo, NotifyWarning } from '../Notifications'

const DeleteProjectModal = ({ isOpen = false,  onClose, projectId, project, getProjects, reloadDashboard=f=>f }) => {

    const { t, i18n } = useTranslation();

    const [state, setState] = React.useState({
        loading: false,
        error: false
    });

    useEffect(() => {

        setState(state => ({...state, loading: true }))

        axios.patch(`/api/projects/${projectId}`, {
            "isDeleted": !project.isDeleted
        })
            .then(res => {
                setState(state => ({...state, loading: false }))
                // dispatch(toggleDeleteProject(project))
                getProjects()
                reloadDashboard()
                !project.isDeleted ? NotifySuccess("Spreadsheet is deleted.") : NotifySuccess("Spreadsheet is restored.")
            })
            .catch(error => {
                setState((state) => ({ ...state, loading: false, error: true }))
                NotifyError("oh no! Something happened!")
        })

    }, [])

    const dispatch = useDispatch()

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={onClose}>
                <span className="modal-title">{t("delete project")}</span>
            </ModalHeader>
            <ModalBody>
                <p className="name">
                    { state.loading && !state.error && <strong>Project is deleting...</strong> }
                    { !state.loading && !state.error && <strong>Project is deleted successfully</strong> }
                    { state.error && <strong>Project could not be deleted</strong> }
                </p>
                <div style={{ height: "8px" }}></div>
            </ModalBody>
            <ModalFooter>
                <button className="discard-button" onClick={onClose}>
                    Okay
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteProjectModal;