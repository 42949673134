import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Navbar,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  Nav,
  Collapse,
  NavbarText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import {
  setFileForRightPanel,
  setupActualProject,
  addFileToProject,
  openViewer,
} from "../../actions";
import GC from "@grapecity/spread-sheets";
import RightPanel from "./RightPanel";
import FileViewer from "./FileViewer";
import axios from "../../axios";
import config from "../../spreadsheetConfig";
import { images } from "../../resources";
import * as ExcelIO from "@grapecity/spread-excelio";
import "@grapecity/spread-sheets-designer-resources-en";
import "@grapecity/spread-sheets-designer-react";
import "@grapecity/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css";
import "../../styles/Spreadsheet.css";
import { withRouter } from "react-router-dom";

class Spreadsheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: "",
      filesChosen: [],
      showModal: false,
      numPages: null,
      pageNumber: 1,
      spread: null,
      showFileInfoRightNavbar: false,
      project_id: this.props.match.params.id,
      dropFileLoading: false,
      fileViewerZoom: 1.0,
      loading: true,
    };

    this.fileUpload = React.createRef();
    this.folderUpload = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  getSelectedText = () => {
    const selectedText = window.getSelection().toString();

    const { spread } = this.state;

    if (selectedText) {
      setTimeout(() => {
        this.setState({ showTagTooltip: true });
      }, 1000);
    }
  };

  handleFileInputChange = (event) => {
    const fileList = Array.from(event.target.files);

    //const file = event.target.files[0];

    const { spread } = this.state;

    fileList.forEach((file, index) => {
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);

      if (
        ext === "pdf" ||
        ext === "docx" ||
        ext === "xlsx" ||
        ext === "word" ||
        ext === "json" ||
        ext === "xml"
      ) {
        const formData = new FormData();
        formData.append("file", file);

        let activeRow = spread.getActiveSheet().getActiveRowIndex();
        let activeColumn = spread.getActiveSheet().getActiveColumnIndex();

        if (index != 0) {
          activeRow += index;
        }

        this.setState({ dropFileLoading: true });

        axios
          .post("/api/files/upload", formData, {
            headers: {
              "x-drag-project-id": this.state.project_id,
            },
          })

          .then((res) => {
            const sheet = spread.getActiveSheet();

            this.props.addFileToProject(res.data[0]);

            let fileType = file.name.split(".").pop();

            if (fileType == "docx" || fileType == "doc") {
              fileType = "word";
            } else if (
              fileType == "xls" ||
              fileType == "csv" ||
              fileType == "xlsx" ||
              fileType == "xlsm"
            ) {
              fileType = "excel";
            }

            sheet.suspendPaint();
            let style = new GC.Spread.Sheets.Style();
            style._buttonBackColor = style.backColor
              ? style.backColor
              : "white";

            style.showEllipsis = true;

            style.cellButtons = [
              {
                caption: "",
                buttonBackColor: style._buttonBackColor,
                hoverBackColor: style._buttonBackColor,
                imageType: GC.Spread.Sheets.ButtonImageType.custom,
                imageSrc: images[fileType],
                position: GC.Spread.Sheets.ButtonPosition.left,
                imageSize: {
                  height: 25,
                  width: 30,
                },
                command: () => {
                  this.importWorkbook(
                    sheet.getTag(activeRow, activeColumn).file,
                    spread
                  );
                },
              },
            ];
            sheet.setStyle(activeRow, activeColumn, style);
            sheet.resumePaint();

            spread
              .getActiveSheet()
              .setValue(activeRow, activeColumn, file.name);

            this.setState({ dropFileLoading: false });
          });

        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {
            //console.log(res)
          });

        this.setState({ spread });
      }
    });
  };
  importWorkbook = (file, spread, callback) => {
    ExcelIO.open(
      file,
      function (json) {
        var workbookObj = json;
        spread.fromJSON(workbookObj, {
          doNotRecalculateAfterLoad: false,
        });
        if (callback) {
          callback();
        }
      },
      function (e) {
        // process error
        alert(e.errorMessage);
      }
    );
  };

  addButtonStyle = (sheet, row, col, fileType, spread) => {
    sheet.suspendPaint();
    let style = sheet.getStyle(row, col) || new GC.Spread.Sheets.Style();
    style._buttonBackColor = style.backColor ? style.backColor : "white";
    style.cellButtons = [
      {
        caption: "",
        buttonBackColor: style._buttonBackColor,
        hoverBackColor: style._buttonBackColor,
        imageType: GC.Spread.Sheets.ButtonImageType.custom,
        imageSrc: images[fileType],
        position: GC.Spread.Sheets.ButtonPosition.left,
        imageSize: {
          height: 30,
          width: 50,
        },
        command: () => {
          this.importWorkbook(sheet.getTag(row, col).file, spread);
        },
      },
    ];
    sheet.setStyle(row, col, style);
    sheet.resumePaint();
  };

  addDragDrop = (spread) => {
    let host = document.getElementById("ss");
    host.addEventListener("dragover", function (e) {
      e.preventDefault();
      e.stopPropagation();
    });
    host.addEventListener("dragstart", function (e) {
      e.preventDefault();
      e.stopPropagation();
    });
    host.addEventListener("dragleave", function (e) {
      e.preventDefault();
      e.stopPropagation();
    });
    host.addEventListener("drop", (e) => {
      e.preventDefault();
      e.stopPropagation();

      const file = e.dataTransfer.files[0];

      const formData = new FormData();
      formData.append("file", file);

      this.setState({ dropFileLoading: true });

      axios
        .post("/api/files/upload", formData, {
          headers: {
            "x-drag-project-id": this.state.project_id,
          },
        })
        .then((res) => {
          const sheet = spread.getActiveSheet();

          this.props.addFileToProject(res.data[0]);

          let fileType = file.name.split(".").pop();

          const x = e.pageX - document.getElementById("ss").offsetLeft;
          const y = e.pageY - document.getElementById("ss").offsetTop - 50;

          const target = spread.hitTest(x, y);

          const row = target.worksheetHitInfo.row;
          const col = target.worksheetHitInfo.col;

          if (fileType == "docx" || fileType == "doc") {
            fileType = "word";
          } else if (
            fileType == "xls" ||
            fileType == "csv" ||
            fileType == "xlsx" ||
            fileType == "xlsm"
          ) {
            fileType = "excel";
          }

          spread
            .getActiveSheet()
            .setTag(target.worksheetHitInfo.row, target.worksheetHitInfo.col, {
              file: file,
              type: fileType,
            });

          sheet.suspendPaint();
          let style = sheet.getStyle(row, col) || new GC.Spread.Sheets.Style();
          style._buttonBackColor = style.backColor ? style.backColor : "white";
          style.cellButtons = [
            {
              caption: "",
              buttonBackColor: style._buttonBackColor,
              hoverBackColor: style._buttonBackColor,
              imageType: GC.Spread.Sheets.ButtonImageType.custom,
              imageSrc: images[fileType],
              position: GC.Spread.Sheets.ButtonPosition.left,
              imageSize: {
                height: 25,
                width: 30,
              },
              command: () => {
                this.importWorkbook(sheet.getTag(row, col).file, spread);
              },
            },
          ];
          sheet.setStyle(row, col, style);
          sheet.resumePaint();

          spread
            .getActiveSheet()
            .setValue(
              target.worksheetHitInfo.row,
              target.worksheetHitInfo.col,
              file.name
            );

          this.setState({ dropFileLoading: false });
        });
    });
  };

  makeSpreadColumnsLarger = (spread) => {
    for (let i = 0; i <= 19; i++) {
      spread.getActiveSheet().setColumnWidth(i, 170);
    }
    for (let i = 0; i <= 200; i++) {
      spread.getActiveSheet().setRowHeight(i, 40);
    }
  };

  loadLicences = () => {
    var sjsLicense = process.env.REACT_APP_SJS_LICENSE;
    GC.Spread.Sheets.LicenseKey = sjsLicense;
    ExcelIO.LicenseKey = sjsLicense;

    GC.Spread.Sheets.Designer.LicenseKey =
      process.env.REACT_APP_SJS_DESIGNER_LICENSE;
  };

  editCellUponEnterPress = (spread) => {
    spread.getHost().addEventListener("keydown", function (e) {
      if (e.keyCode !== 113) {
        return;
      }
      var sheet = spread.getActiveSheet();
      if (sheet.isEditing()) {
        return;
      }
      // start editing cell
      sheet.startEdit();
    });
  };

  navigateCellsWithKeyboardArrows = (spread) => {
    spread.bind("SelectionChanged", (e, args) => {
      const { row: activeRow, col: activeColumn } = args.newSelections[0];

      const value = spread.getActiveSheet().getValue(activeRow, activeColumn);

      if (value !== null) {
        const foundFileName = this.props.project_files.find(
          (file) => file.filename === value
        );

        if (foundFileName) {
          this.props.setFileForRightPanel(
            activeColumn,
            activeRow,
            foundFileName
          );
        }
      }
    });
  };

  addCountWordsFormula = (spread) => {
    const project_id = this.state.project_id;
    const { project_files } = this.props;
    var formula = function () {};

    formula.prototype = new GC.Spread.CalcEngine.Functions.AsyncFunction(
      "FILE.countWords",
      1,
      255
    );

    formula.prototype.defaultValue = function () {
      return "Loading...";
    };

    formula.prototype.evaluateAsync = function (context) {
      const args = arguments;
      const fileName = args[1];
      const fileId = project_files.find(
        (file) => file.filename === fileName
      ).id;

      if (fileId) {
        axios
          .post("/api/functions/evaluate", {
            pid: project_id,
            func: "FILE.countWords",
            args: [fileId],
          })
          .then((res) => {
            if (res.data.result !== null) {
              context.setAsyncResult(res.data.result);
            } else {
              context.setAsyncResult("#NAME?");
            }
            //spread.resumePaint();
          });
      } else {
        context.setAsyncResult("File not found");
        //spread.resumePaint();
      }
    };

    formula.prototype.description = function () {
      return {
        description:
          "Function that helps to get the number of words of a pdf document.",
        parameters: [
          {
            name: "value",
          },
        ],
      };
    };

    spread.addCustomFunction(new formula());
  };

  addGetTagFromFileFormula = (spread) => {
    const project_id = this.state.project_id;
    const { project_files } = this.props;

    var formula1 = function () {
      this.typeName = "FILE.tag";
    };

    formula1.prototype = new GC.Spread.CalcEngine.Functions.AsyncFunction(
      "FILE.tag",
      1,
      255
    );

    formula1.prototype.defaultValue = function () {
      return "Loading...";
    };

    formula1.prototype.evaluateAsync = function (context) {
      const args = arguments;

      const fileName = args[1];
      const key = args[2];
      const fileId = project_files.find(
        (file) => file.filename === fileName
      ).id;

      if (fileId) {
        axios
          .post("/api/functions/evaluate", {
            pid: project_id,
            func: "FILE.tag",
            args: [fileId, key],
          })
          .then((res) => {
            if (res.data.result !== null) {
              context.setAsyncResult(res.data.result);
            } else {
              context.setAsyncResult("#NAME?");
            }
          });
      } else {
        context.setAsyncResult("File not found");
      }
    };

    formula1.prototype.description = function () {
      return {
        description: "Function used to get the tag from a file.",
        parameters: [
          {
            name: "File Name",
            tag: "Tag Name",
          },
        ],
      };
    };

    spread.addCustomFunction(new formula1());
  };

  addGetFileMetadataFormula = (spread) => {
    const project_id = this.state.project_id;
    const { project_files } = this.props;
    var formula2 = function () {};

    formula2.prototype = new GC.Spread.CalcEngine.Functions.AsyncFunction(
      "FILE.metadata",
      1,
      255
    );

    formula2.prototype.defaultValue = function () {
      return "Loading...";
    };

    formula2.prototype.evaluateAsync = function (context) {
      const args = arguments;

      const fileName = args[1];
      const key = args[2];
      const fileId = project_files.find(
        (file) => file.filename === fileName
      ).id;

      if (fileId) {
        axios
          .post("/api/functions/evaluate", {
            pid: project_id,
            func: "FILE.metadata",
            args: [fileId, key],
          })
          .then((res) => {
            context.setAsyncResult(JSON.stringify(res.data.result));
          });
      } else {
        context.setAsyncResult("File not found");
      }
    };

    formula2.prototype.description = function () {
      return {
        description: "Function get metadata object of a pdf file.",
        parameters: [
          {
            name: "File Name",
            jsonPath: "JSON Path",
          },
        ],
      };
    };

    spread.addCustomFunction(new formula2());
  };

  componentWillUnmount() {
    // if(!!this.spread) {
    this.spread?.destroy();
    this.spreadsheet?.destroy();
    // }
  }

  componentDidMount = async () => {
    if (!document.getElementById("ss")) {
      const host = document.createElement("div");
      host.setAttribute("id", "ss");
      document.body.appendChild(host);
    }

    await this.loadLicences();

    this.folderUpload.current.setAttribute("directory", true);
    this.folderUpload.current.setAttribute("webkitdirectory", true);

    const spread = new GC.Spread.Sheets.Workbook(document.getElementById("ss"));
    this.spread = spread;

    spread.options.allowUserDragDrop = true;

    this.addDragDrop(spread);
    this.makeSpreadColumnsLarger(spread);
    this.editCellUponEnterPress(spread);
    this.navigateCellsWithKeyboardArrows(spread);

    axios.get(`/api/projects/${this.state.project_id}`).then(async (res) => {
      const JSONstr = res.data.data;
      this.setState({
        spreadsheetName: res.data.name,
      });

      if (JSONstr) {
        spread.suspendCalcService();
        
        spread.fromJSON(JSON.parse(res.data.data));
        spread.setSheetCount(Object.keys(JSON.parse(JSONstr).sheets).length);

        spread.resumeCalcService(true);
      }

      const sheet = spread.getActiveSheet();

      const range = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
      sheet.rowFilter(new GC.Spread.Sheets.Filter.HideRowFilter(range));

      const files = this.props.project_files;
      config.commandMap.uploadFile.execute = () =>
        this.fileUpload.current.click();
      config.commandMap.uploadFolder.execute = () =>
        this.folderUpload.current.click();

      const rowCount = sheet.getRowCount();
      const colCount = sheet.getColumnCount();

      // Get the command manager
      var cm = spread.commandManager();
      // register the custom keyboard shortcuts with the custom selectColumn funciton
      cm.register("ctrlTab", selectColumn);

      // Set the shortcut keys, 32 is the keycode for space bar
      spread
        .commandManager()
        .setShortcutKey("ctrlTab", 32, true, false, false, false);

      // Custom setSelection function -- set the selection for the entire current active column
      function selectColumn(spread) {
        var sheet = spread.getActiveSheet();
        var activeColumnIndex = sheet.getActiveColumnIndex();
        var rowCount = sheet.getRowCount();

        sheet.setSelection(0, activeColumnIndex, rowCount, 1);
      }

      cm.setShortcutKey(null, 9, false, true, false, false);

      // register the custom keyboard shortcuts with the custom selectColumn function
      cm.register("shiftTab", selectRow);

      // Set the shortcut keys, 9 is the keycode for Tab key
      spread
        .commandManager()
        .setShortcutKey("shiftTab", 9, false, true, false, false); // shift + tab

      // Custom setSelection function -- set the selection for the entire current active column
      function selectRow(spread) {
        var sheet = spread.getActiveSheet();
        var activeRowIndex = sheet.getActiveRowIndex();
        var columnCount = sheet.getColumnCount();

        sheet.setSelection(activeRowIndex, 0, 1, columnCount);
      }

      var style = new GC.Spread.Sheets.Style();
      style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
      style.showEllipsis = true;
      sheet.setDefaultStyle(style);
      
      spread.getHost().addEventListener("keydown", function (e) {
        if (e.ctrlKey && e.keyCode === 49) {
          GC.Spread.Sheets.Designer.getCommand("formatCells").execute(spread);
        }
      });

      await this.addCountWordsFormula(spread);
      await this.addGetTagFromFileFormula(spread);
      await this.addGetFileMetadataFormula(spread);

      spread.getActiveSheet().recalcAll(true);

      const spreadsheet = new GC.Spread.Sheets.Designer.Designer(
        document.getElementById("gc-designer-container"),
        config,
        spread
      );

      this.spreadsheet = spreadsheet;

      this.setState({ spread: spread, loading: false });
    });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.SheetChanged, (sender, args) => {
        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {});
      });

    this.props.setupActualProject(this.state.project_id);
    this.setState({ spread });
  };

  componentDidUpdate = async () => {
    const { filesChosen, spread } = this.state;

    await this.addCountWordsFormula(spread);
    await this.addGetTagFromFileFormula(spread);
    await this.addGetFileMetadataFormula(spread);

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.CellDoubleClick, (sender, args) => {
        const activeRow = args.row;
        const activeColumn = args.col;

        const value = spread.getActiveSheet().getValue(activeRow, activeColumn);

        if (value !== null) {
          const newValue = filesChosen.filter(function (file) {
            return file.name === value;
          });

          if (newValue) {
            this.setState({
              activeFile: newValue[0],
              showModal: true,
            });
          }
        }
      });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.CellChanged, (sender, args) => {
        const { row, col, newValue, oldValue } = args;
        
        if (oldValue) {
          const sheet = spread.getActiveSheet();
          const foundFileName = this.props.project_files.find(
            (file) => file.filename === newValue
          );

          if (!foundFileName) {
            let style = sheet.getStyle(row, col);
            if (style && style.cellButtons && style.cellButtons.length > 0) {
              style.cellButtons = [];
              sheet.setStyle(row, col, style);
            }
          }
        }

        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {
          });
      });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.ColumnChanged, (sender, args) => {
        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {
          });
      });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.RowChanged, (sender, args) => {
        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {
           
          });
      });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.ColumnWidthChanged, (sender, args) => {
        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {
            
          });
      });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.RowHeightChanged, (sender, args) => {
        axios
          .patch(`/api/projects/${this.state.project_id}`, {
            data: JSON.stringify(spread.toJSON()),
          })
          .then((res) => {
            
          });
      });

    spread
      .getActiveSheet()
      .bind(GC.Spread.Sheets.Events.CellDoubleClick, (sender, args) => {
        const activeRow = args.row;
        const activeColumn = args.col;

        const value = spread.getActiveSheet().getValue(activeRow, activeColumn);
        
        if (value !== null) {
          const foundFileName = this.props.project_files.find(
            (file) => file.filename === value
          );

          if (foundFileName) {
            this.props.setFileForRightPanel(
              activeColumn,
              activeRow,
              foundFileName
            );
            this.props.openViewer();
          }
        }
      });
  };

  saveSpread = (spread) => this.setState({ spread });

  setZoomValue = (val) => this.setState({ fileViewerZoom: val });

  recalcFormulas = () => {
    const { spread } = this.state;
    spread.suspendCalcService();
    spread.getActiveSheet().recalcAll(true);
    spread.resumeCalcService(true);
    this.setState({ spread });
  };

  render() {
    const { activeFile, loading } = this.state;

    return (
      <>
        {loading && (
          <div className="loading-parent-div">
            <Spinner color="info" />
          </div>
        )}

        <Navbar color="light" light expand="md">
          <NavbarBrand onClick={() => this.props.history.push("/")}>
            <i
              className="fa fa-arrow-left"
              style={{ color: "rgb(0, 144, 209)", cursor: "pointer" }}
            ></i>
          </NavbarBrand>
          <NavbarToggler />
          <Collapse isOpen={true} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                {/* <NavLink href="/components/"> */}
                {this.state.spreadsheetName && this.state.spreadsheetName}
                {/* </NavLink> */}
              </NavItem>
            </Nav>
            <NavbarText>
              {!this.state.dropFileLoading ? (
                <>
                  Sei aggiornato
                  <i
                    className="fa fa-check"
                    style={{
                      color: "rgb(9, 130, 42)",
                      padding: "0px 5px",
                      fontSize: "20px",
                    }}
                  ></i>
                </>
              ) : (
                <>Salvataggio...</>
              )}
            </NavbarText>
          </Collapse>
        </Navbar>

        <input
          ref={this.fileUpload}
          onChange={this.handleFileInputChange}
          id="file-input"
          type="file"
          name="name"
          multiple
          style={{ display: "none" }}
          accept=".xlsx,.xls,.doc, .docx, .pdf, .json, .xml"
        />
        <input
          ref={this.folderUpload}
          onChange={this.handleFileInputChange}
          id="folder-input"
          type="file"
          name="name"
          style={{ display: "none" }}
          accept=".xlsx,.xls,.doc, .docx, .pdf, .json, .xml"
        />

        {this.props.showFileInfoRightNavbar && (
          <Row className="spreadsheet-files-parent">
            <Col
              sm="3"
              style={{
                backgroundColor: this.props.showViewer
                  ? "rgba(0, 80, 117, 0.32)"
                  : "none",
                zIndex: this.props.showViewer && "3",
                height: "94%",
              }}
            >
              <div id="tagger-target"></div>
            </Col>
            <Col sm="6" className={this.props.showViewer ? "file-viewer" : ""}>
              {this.props.showViewer && (
                <FileViewer
                  spread={this.state.spread}
                  saveSpread={this.saveSpread}
                  fileViewerZoom={this.state.fileViewerZoom}
                  updateFileViewerZoom={this.setZoomValue}
                  recalcFormulasAfterTagAddition={this.recalcFormulas}
                />
              )}
            </Col>
            <Col sm="3" className="file-info-right">
              <RightPanel
                fileViewerZoom={this.state.fileViewerZoom}
                updateFileViewerZoom={this.setZoomValue}
                recalcFormulasAfterTagDeletion={this.recalcFormulas}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    showFileInfoRightNavbar: state.spreadsheet.showRightPanel,
    project_files: state.spreadsheet.project_files,
    activeFile: state.spreadsheet.activeFile,
    showViewer: state.spreadsheet.openViewer,
  };
}

export default connect(mapStateToProps, {
  setupActualProject,
  setFileForRightPanel,
  addFileToProject,
  openViewer,
})(withRouter(Spreadsheet));
