let config = {
    "ribbon": [
        {
            "id": "home",
            "text": "HOME",
            "buttonGroups": [
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-undoredo",
                    "commandGroup": {
                        "children": [
                            {
                                "commands": [
                                    "undo",
                                    "redo",
                                    
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-clipboard",
                    "commandGroup": {
                        "children": [
                            {
                                "commands": [
                                    "copy",
                                    "pasteAll",
                                    "cut"

                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "",
                    "indicator": "indicatorFonts",
                    "thumbnailClass": "ribbon-thumbnail-fonts",
                    "commandGroup": {
                        "children": [
                            {
                                "commands": [
                                    "fontFamily",
                                    "fontSize",
                                    "increaseFontsize",
                                    "decreaseFontsize"
                                ]
                            }
                        ]
                    }
                },{
                    "label": "",
                    "indicator": "indicatorFonts",
                    "thumbnailClass": "ribbon-thumbnail-fonts",
                    "commandGroup": {
                        "children": [
                            {
                                "commands": [
                                    "fontWeight",
                                    "fontItalic",
                                    "fontUnderline",
                                    "backColor",
                                    "foreColor"
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "",
                    "indicator": "indicatorAlignment",
                    "thumbnailClass": "ribbon-thumbnail-alignment",
                    "commandGroup": {
                        "children": [
                            {
                                "children": [
                                    {
                                        "commands": [
                                            "leftAlign",
                                            "centerAlign",
                                            "rightAlign",
                                        ]
                                    }
                                ]
                            },
                        ]
                    }
                },
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-shapes",
                    "commandGroup": {
                        "commands": [
                            "insertShape"
                        ]
                    }
                },
            ]
        },
        {
            "id": "tableDesign",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "Properties",
                    "thumbnailClass": "ribbon-thumbnail-properties",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "commands": [
                                    "tableName",
                                    "resizeTable"
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "Tools",
                    "thumbnailClass": "ribbon-thumbnail-tools",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "commands": [
                                    "convertToRange",
                                    "tableAllowAutoExpand"
                                ]
                            },
                            {
                                "commands": [
                                    "insertSlicer"
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "Table Style Options",
                    "thumbnailClass": "ribbon-thumbnail-tablestyleoptions",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "commands": [
                                    "tableStyleHeaderRow",
                                    "tableStyleTotalRow",
                                    "tableStyleBandedRows"
                                ]
                            },
                            {
                                "direction": "vertical",
                                "commands": [
                                    "tableStyleFirstColumn",
                                    "tableStyleLastColumn",
                                    "tableStyleBandedColumns"
                                ]
                            },
                            {
                                "direction": "vertical",
                                "commands": [
                                    "tableStyleResizeHandler",
                                    "tableStyleFilterButton",
                                    "tableStyleTotalRowList"
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "Table Styles",
                    "thumbnailClass": "ribbon-thumbnail-tablestyles",
                    "commandGroup": {
                        "commands": [
                            "formatTable"
                        ]
                    }
                }
            ],
            "visibleWhen": "TableSelected"
        },
        {
            "id": "chartDesign",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "Chart Layouts",
                    "thumbnailClass": "ribbon-thumbnail-chartlayouts",
                    "commandGroup": {
                        "commands": [
                            "addChartElement",
                            "quickLayout"
                        ]
                    }
                },
                {
                    "label": "Chart Styles",
                    "thumbnailClass": "ribbon-thumbnail-chartstyles",
                    "commandGroup": {
                        "commands": [
                            "changeColors",
                            "chartStyle"
                        ]
                    }
                },
                {
                    "label": "Data",
                    "thumbnailClass": "ribbon-thumbnail-chartdata",
                    "commandGroup": {
                        "commands": [
                            "switchRowColumn",
                            "selectData"
                        ]
                    }
                },
                {
                    "label": "Type",
                    "thumbnailClass": "ribbon-thumbnail-charttype",
                    "commandGroup": {
                        "commands": [
                            "changeChartType"
                        ]
                    }
                },
                {
                    "label": "Location",
                    "thumbnailClass": "ribbon-thumbnail-chartlocation",
                    "commandGroup": {
                        "commands": [
                            "moveChart"
                        ]
                    }
                },
                {
                    "label": "Accessiblity",
                    "thumbnailClass": "ribbon-thumbnail-chart-alt-text",
                    "commandGroup": {
                        "commands": [
                            "chartAltText"
                        ]
                    }
                }
            ],
            "visibleWhen": "singleChartSelected"
        },
        {
            "id": "pictureDesign",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "Accessiblity",
                    "thumbnailClass": "ribbon-thumbnail-chart-alt-text",
                    "commandGroup": {
                        "commands": [
                            "pictureAltText"
                        ]
                    }
                }
            ],
            "visibleWhen": "pictureSelected && !IsProtected || pictureSelected && AllowEditObject"
        },
        {
            "id": "shapeDesign",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-shapeinsertshape",
                    "commandGroup": {
                        "commands": [
                            "insertShapeList",
                            "changeShape"
                        ]
                    }
                },
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-shapeStyles",
                    "commandGroup": {
                        "commands": [
                            "changeShapeStyle"
                        ]
                    }
                },
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-chart-alt-text",
                    "commandGroup": {
                        "commands": [
                            "shapeAltText"
                        ]
                    }
                },
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-shaperotate",
                    "commandGroup": {
                        "commands": [
                            "shapeRotate"
                        ]
                    }
                },
                {
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-shapegroup",
                    "commandGroup": {
                        "commands": [
                            "shapeCommandGroup"
                        ]
                    }
                },
                {
                    "class": "gc-ribbon-panelgroup-shapeSize",
                    "label": "",
                    "thumbnailClass": "ribbon-thumbnail-shapesize",
                    "commandGroup": {
                        "direction": "vertical",
                        "commands": [
                            "shapeSizeHeight",
                            "shapeSizeWidth"
                        ]
                    }
                }
            ],
            "visibleWhen": "ShapeSelected && !IsProtected || ShapeSelected && AllowEditObject"
        },
        {
            "id": "slicerDesign",
            "text": "OPTIONS",
            "buttonGroups": [
                {
                    "class": "slicer_panel",
                    "label": "Slicer",
                    "thumbnailClass": "ribbon-thumbnail-properties",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "type": "group",
                                "commands": [
                                    "captionName",
                                    "slicerSetting"
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "Slicer Styles",
                    "thumbnailClass": "ribbon-thumbnail-tablestyles",
                    "commandGroup": {
                        "commands": [
                            "slicerFormat"
                        ]
                    }
                },
                {
                    "class": "slicer_buttons",
                    "label": "Buttons",
                    "thumbnailClass": "ribbon-thumbnail-properties",
                    "commandGroup": {
                        "direction": "vertical",
                        "commands": [
                            "columnCount",
                            "itemHeight",
                            "itemWidth"
                        ]
                    }
                },
                {
                    "class": "slicer_size",
                    "label": "Size",
                    "thumbnailClass": "ribbon-thumbnail-sliceSize",
                    "commandGroup": {
                        "direction": "vertical",
                        "commands": [
                            "slicerHeight",
                            "slicerWidth"
                        ]
                    }
                }
            ],
            "visibleWhen": "SlicerSelected && !IsProtected || SlicerSelected && AllowEditObject"
        },
        {
            "id": "formulaSparkLineDesign",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "Argument",
                    "thumbnailClass": "ribbon-thumbnail-argument",
                    "commandGroup": {
                        "commands": [
                            "formulaSparklineSetting"
                        ]
                    }
                }
            ],
            "visibleWhen": "FormulaSparklineSelected"
        },
        {
            "id": "sparkLineDesign",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "Type",
                    "thumbnailClass": "ribbon-thumbnai-sparkline-type",
                    "commandGroup": {
                        "commands": [
                            "lineSparkline",
                            "columnSparkline",
                            "winLossSparkline"
                        ]
                    }
                },
                {
                    "label": "Show",
                    "thumbnailClass": "ribbon-thumbnail-sparkline-show",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "commands": [
                                    "showHighpoint",
                                    "showLowPoint",
                                    "showNegativePoint"
                                ]
                            },
                            {
                                "direction": "vertical",
                                "commands": [
                                    "showFirstPoint",
                                    "showLastPoint",
                                    "showMarkers"
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "Style",
                    "thumbnailClass": "ribbon-thumbnail-sparkline-style",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "children": [
                                    {
                                        "direction": "vertical",
                                        "commands": [
                                            "sparklineColor",
                                            "sparklineMarkerColor"
                                        ]
                                    },
                                    {
                                        "commands": [
                                            "sparklineWeight"
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "label": "Group",
                    "thumbnailClass": "ribbon-thumbnail-sparkline-groups",
                    "commandGroup": {
                        "children": [
                            {
                                "direction": "vertical",
                                "children": [
                                    {
                                        "direction": "vertical",
                                        "commands": [
                                            "sparklineGroup",
                                            "sparklineUnGroup"
                                        ]
                                    },
                                    {
                                        "commands": [
                                            "clearSparkline",
                                            "clearSparklineDropdown"
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                }
            ],
            "visibleWhen": "SparklineSelected"
        },
        {
            "id": "barcodeDesignId",
            "text": "DESIGN",
            "buttonGroups": [
                {
                    "label": "Argument",
                    "thumbnailClass": "ribbon-thumbnail-argument",
                    "commandGroup": {
                        "commands": [
                            "barCodeSetting"
                        ]
                    }
                }
            ],
            "visibleWhen": "BarCodeSelected"
        }
    ],
    "contextMenu": [
        "contextMenuCut",
        "contextMenuCopy",
        "contextMenuPaste",
        "designerPasteAll",
        "pasteFormula",
        "designerPasteValues",
        "designerPasteFormatting",
        "pasteValuesFormatting",
        "pasteFormulaFormatting",
        "floatingObjectCut",
        "floatingObjectCopy",
        "contextMenuCutShapes",
        "contextMenuCopyShapes",
        "PasteShapes",
        "separator",
        "resetChartColor",
        "changeChartTypeDialog",
        "selectChartDataDialog",
        "moveChartDialog",
        "formatChart",
        "separator",
        "insertDialog",
        "uploadFile",
        "uploadFolder",
        "deleteDialog",
        "gc.spread.contextMenu.insertColumns",
        "gc.spread.contextMenu.deleteColumns",
        "gc.spread.contextMenu.insertRows",
        "gc.spread.contextMenu.deleteRows",
        "tableInsert",
        "tableDelete",
        "gc.spread.contextMenu.clearContents",
        "separator",
        "gc.spread.contextMenu.rowHeaderinsertCopiedCells",
        "gc.spread.contextMenu.rowHeaderinsertCutCells",
        "gc.spread.contextMenu.colHeaderinsertCopiedCells",
        "gc.spread.contextMenu.colHeaderinsertCutCells",
        "insertCopiedCells",
        "insertCutCells",
        "separator",
        "gc.spread.contextMenu.insertSheet",
        "gc.spread.contextMenu.deleteSheet",
        "unprotectSheet",
        "protectSheet",
        "separator",
        "gc.spread.contextMenu.filter",
        "sort",
        "table",
        "separator",
        "gc.spread.contextMenu.insertComment",
        "gc.spread.contextMenu.editComment",
        "gc.spread.contextMenu.deleteComment",
        "gc.spread.contextMenu.toggleComment",
        "formatComment",
        "separator",
        "formatCells",
        "editCellType",
        "editCellDropdowns",
        "link",
        "editHyperlink",
        "openHyperlink",
        "removeHyperlink",
        "removeHyperlinks",
        "separator",
        "richText",
        "defineName",
        "cellTag",
        "rowTag",
        "colTag",
        "openDialog",
        "columnWidth",
        "rowHeight",
        "gc.spread.contextMenu.hideColumns",
        "gc.spread.contextMenu.hideRows",
        "gc.spread.contextMenu.unhideColumns",
        "gc.spread.contextMenu.unhideRows",
        "columnHeaders",
        "contextMenuOutlineColumn",
        "rowHeaders",
        "separator",
        "showTabColor",
        "gc.spread.contextMenu.hideSheet",
        "gc.spread.contextMenu.unhideSheet",
        "sheetTag",
        "separator",
        "gc.spread.contextMenu.cut",
        "gc.spread.contextMenu.copy",
        "slicerPasteOptions",
        "gc.spread.contextMenu.pasteAll",
        "gc.spread.contextMenu.slicerSortAscend",
        "gc.spread.contextMenu.slicerSortDescend",
        "gc.spread.contextMenu.removeSlicer",
        "slicerProperty",
        "contextMenuSlicerSetting",
        "separator",
        "groupShape",
        "formatShapes",
        "designerMoreFunctions",
        "designer.renameFile"
    ],
    "fileMenu": "fileMenuButton",
    "sidePanels": [
        {
            "position": "top",
            "allowResize": true,
            "command": "formulaBarPanel",
            "uiTemplate": "formulaBarTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "fieldListTreePanel",
            "uiTemplate": "filedListTemplate"
        },
        {
            "position": "bottom",
            "command": "statusBarPanel",
            "uiTemplate": "statusBarPanelTemplate"
        },
        {
            "position": "full",
            "command": "fileMenuPanel",
            "uiTemplate": "fileMenuPanelTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "shapePanel",
            "uiTemplate": "shapeTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "columnChartPanel",
            "uiTemplate": "columnChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "lineChartPanel",
            "uiTemplate": "lineChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "pieChartPanel",
            "uiTemplate": "pieChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "barChartPanel",
            "uiTemplate": "barChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "areaChartPanel",
            "uiTemplate": "areaChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "scatterChartPanel",
            "uiTemplate": "scatterChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "stockChartPanel",
            "uiTemplate": "stockChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "radarChartPanel",
            "uiTemplate": "radarChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "sunburstChartPanel",
            "uiTemplate": "sunburstChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "treeMapChartPanel",
            "uiTemplate": "treeMapChartPanelTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "funnelChartPanel",
            "uiTemplate": "funnelChartPanelTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "comboChartPanel",
            "uiTemplate": "comboChartTemplate"
        },
        {
            "position": "right",
            "width": "315px",
            "command": "chartAltTextPanel",
            "uiTemplate": "altTextTemplate",
            "showCloseButton": true
        },
        {
            "position": "right",
            "width": "315px",
            "command": "pictureAltTextPanel",
            "uiTemplate": "altTextTemplate",
            "showCloseButton": true
        },
        {
            "position": "right",
            "width": "315px",
            "command": "shapeAltTextPanel",
            "uiTemplate": "altTextTemplate",
            "showCloseButton": true
        }
    ]
}

config.commandMap = {
    uploadFile: {
        title: "Upload File",
        text: "Upload File",
        iconClass: "uploadFile",
        bigButton: "true",
        commandName: "uploadFile",
    },
    uploadFolder: {
        title: "Upload Folder",
        text: "Upload Folder",
        iconClass: "uploadFolder",
        bigButton: "true",
        commandName: "uploadFolder",
    },
}


export default config;