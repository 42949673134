import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "../../axios";
import ModalComponent from "./ModalComponent";

class FileViewer extends Component{
    constructor(props){
        super(props);
        this.state = {
            file: null
        };

        this.fileUpload = React.createRef();
    }

    // state = {
    //     file: null,
    // }

    componentDidMount() {
        axios.get(`/api/files/${this.props.activeFile.id}/download`, {
            responseType: "blob"
        })
            .then(res => this.setState({ file: res.data }))
    }

    render() {
        return (
            <div id="file-content">
                {this.state.file !== null && (
                    <ModalComponent
                        isOpen={true}
                        //onTextClick={this.getSelectedText}
                        file={this.state.file}
                        fileType={this.props.activeFile.filename.split('.').pop()}
                        spread={this.props.spread}
                        saveSpread={this.props.saveSpread}
                        fileViewerZoom={this.props.fileViewerZoom}
                        onModalClose={this.handleCloseModal}
                        updateFileViewerZoom={this.props.updateFileViewerZoom}
                        recalcFormulasAfterTagAddition={this.props.recalcFormulasAfterTagAddition}
                        //customNames={spread.getActiveSheet().getCustomNames()}
                    />
                )}
            </div>
        );
    }
};

function mapStateToProps(state){
    return{
        activeFile: state.spreadsheet.activeFileRightPanel.fileObject
    }
}

export default connect(mapStateToProps)(FileViewer);