const en = {
    translations: {
        "Data Manager": "Document Tag helps you organize and process information from your files at an instant. Organize your data now.",
        "All Projects": "All Project",
        "spreadsheet in folders": "spreadsheets in this folder",
        "Recent": "Recent",
        "Favourites": "Favourites",
        "Archived": "Archived",
        "Deleted": "Deleted",
        "All spreadsheets": "All spreadsheets",
        "all in folder": "spreadsheets in this folder",
        "search": "Search",
        "open": "Open",
        "create": "Create",
        "create new": "Create new",
        "create new spreadsheet": "Create a new spreadsheet",
        "spreadsheet name": "Spreadsheet name",
        "name your spreadsheet": "Name your spreadsheet (optional)",
        "name your spreadsheet info": "You can rename your spreadsheet later at anytime.",
        "added favorites": "Added to Favorites",
        "add favorites": "Add to Favorites",
        "rename": "Rename Project",
        "archive": "Archive",
        "download": "Download all files inside",
        "delete": "Delete this spreadsheet",
        "spread size": "Spreadsheet size:",
        "today": "Today",
        "yesterday": "Yesterday",
        "last week": "Last week",
        "last opened": "Last opened",
        "last updated": "Last updated",
        "seconds ago": " seconds ago",
        "minutes ago": " minutes ago",
        "hours ago":" hours ago",
        "days ago": " days ago",

        "change data": "CHANGE USER DATA", 
        "manage data text": "Manage the data related to your user profile",
        "discard": "Discard",
        "save": "Save Changes",
        "delete project": "Delete this spreadsheet",
        "edit profile": "Edit Profile",
        "fullname": "Name Surname",
        "name": "Name",
        "surname": "Surname",
        "company": "Company (optional)",
        "company name": "Company Name",
        "username": "Username (can not be changed)",
        "change password": "Change Password",
        "current pass": "Current password",
        "required": "(Required)",
        "current pass info": "Type your current password to be able to change to a new one.",
        "new pass": "New Password",
        "new pass info": "We will log you out on other browsers to keep you secure.",
        "confirm new pass": "Confirm new password",
        "confirm pass": "Confirm password",
        "edit": "Edit",
        "exit": "Log out",
        "cancel": "Cancel",
        "delete button": "Delete",
        "my profile": "My Profile",

        "no data": "You have not created anything",
        "start manage data": "Start managing your data by creating a spreadsheet",
        "suported files": "Supported files: ",

        "cell": "Cell",
        "file type": "File type",
        "view file": "View file",
        "edit tag": "Edit Tag",
        "tags": "Tags",
        "search tag": "Search tag",

    }
}

export default en;