import React, {useEffect, useState} from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {updateActiveLeftNavbarOption, addNewProject, fetchAllProjects} from "../actions";
import { CHANGE_APP_LANGUAGE } from "../actions/types";
import {
  Row,
  Col,
  Container,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Modal,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileSpreadsheet,
  faArchive,
  faTrashAlt,
  faStar,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { faUser, faEdit } from "@fortawesome/free-solid-svg-icons";
import "../styles/Dashboard.css";
import DashboardAllProjects from "../components/Dashboard/DashboardAllProjects";
import DashboardRecentProjects from "../components/Dashboard/DashboardRecentProjects";
import DashboardFavourites from "../components/Dashboard/DashboardFavourites";
import DashboardArchived from "../components/Dashboard/DashboardArchived";
import DashboardDeleted from "../components/Dashboard/DashboardDeleted";
import ProfileSettings from "./ProfileSettings";
import axios from "../axios";
import DashboardNoProjects from "../components/Dashboard/DashbordNoProjects";

const Dashboard = (props) => {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    languageSelected: "en",
    showEditProfileModal: false,
    showNewProjectModal: false,
    spreadsheetName: "",
    openAlert: false,
    errorMessage: "",
    createdNewSpreadsheet: false,
    showUserPopover: false,
    meData: [],
    allProjects: [],
    activeLeftNavbarMenu: "All Projects",
  });

  useEffect(async () => {
    await dispatch(fetchAllProjects());
  }, []);

  React.useEffect(() => {
    getMeInformation();
  }, []);

  React.useEffect(() => {
    getAllProjects();
  }, [state.createdNewSpreadsheet]);

  const dispatch = useDispatch();
  const activeLeftNavbarMenu = useSelector(
    (state) => state.dashboard.activeLeftNavbarMenu
  );
  const languageSelected = useSelector((state) => state.language);
  const projects = useSelector((state) => state.dashboard.projects);
  React.useEffect(() => {
    getAllProjects();
  }, []);

  // let deletedProjects = (projects && projects.items) && projects.items.filter(project => project.isDeleted)
  let deletedProjects =
    state.allProjects &&
    state.allProjects.items &&
    state.allProjects.items.filter((project) => project.isDeleted);
  let archivedProjects =
    state.allProjects &&
    state.allProjects.items &&
    state.allProjects.items.filter((project) => project.isArchived);
  let favoritesProjects =
    state.allProjects &&
    state.allProjects.items &&
    state.allProjects.items.filter((project) => project.isFavorite);

  const leftsideMenu = [
    {
      label: "All Projects",
      text: t("All Projects"),
      icon: faFileSpreadsheet,
      disable: false,
    },
    {
      label: "Recent",
      text: t("Recent"),
      icon: faClock,
      disable:
        state.allProjects &&
        state.allProjects.items &&
        state.allProjects.items.length === 0
          ? true
          : false,
    },
    {
      label: "Favourites",
      text: t("Favourites"),
      icon: faStar,
      disable:
        favoritesProjects !== undefined && favoritesProjects.length === 0
          ? true
          : false,
    },
    {
      label: "Archived",
      text: t("Archived"),
      icon: faArchive,
      disable:
        archivedProjects !== undefined && archivedProjects.length === 0
          ? true
          : false,
    },
    {
      label: "Deleted",
      text: t("Deleted"),
      icon: faTrashAlt,
      disable:
        deletedProjects !== undefined && deletedProjects.length === 0
          ? true
          : false,
    },
  ];

  const getAllProjects = () => {
    axios
      .get("/api/projects")
      .then((res) => setState((state) => ({ ...state, allProjects: res.data })))
      .catch((err) => console.log(err));
  };

  const getMeInformation = () => {
    axios
      .get("/api/me")
      .then((res) => setState((state) => ({ ...state, meData: res.data })))
      .catch((err) => console.log("error", err));
  };

  const showEditProfileModal = () => {
    setState((state) => ({
      ...state,
      showEditProfileModal: true,
    }));
  };

  const handleCancel = () => {
    setState((state) => ({
      ...state,
      showEditProfileModal: false,
    }));
  };

  const handleCancelNewProject = () => {
    setState((state) => ({
      ...state,
      showNewProjectModal: false,
      spreadsheetName: "",
    }));
  };

  const showModal = () => {
    setState((state) => ({
      ...state,
      showNewProjectModal: true,
    }));
  };

  const handleSpreadsheetNameChange = (e) => {
    e.persist();
    setState((state) => ({
      ...state,
      spreadsheetName: e.target.value,
    }));
  };

  const handleUserPopover = () => {
    setState((state) => ({
      ...state,
      showUserPopover: !state.showUserPopover,
    }));
  };

  const createSpreadsheet = (e) => {
    e.preventDefault();
    let postData = {
      name: state.spreadsheetName,
      description: "",
      isDeleted: false,
      isFavorite: false,
      isArchived: false,
      data: {},
    };
    axios
      .post("/api/projects", postData)
      .then((response) => {
        dispatch(addNewProject(response.data));

        setState((state) => ({
          ...state,
          showNewProjectModal: false,
          spreadsheetName: "",
          createdNewSpreadsheet: true,
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          errorMessage: error.message,
          openAlert: true,
          spreadsheetName: "",
        }));
      });
  };

  return (
    <div className="parent">
      {state.showEditProfileModal && (
        <ProfileSettings
          isOpen={state.showEditProfileModal}
          profileData={getMeInformation}
          handleCancel={handleCancel}
        />
      )}

      {/* <div className="top-navbar">
        <p className="logo">
          <span className="first-text">Data</span>
          <span className="second-text">Manager</span>
        </p>

        <div className="right-aligned-items">
          <select
            id="langselector"
            onChange={({ target: { value } }) => {
              i18n.changeLanguage(value);
            }}
          >
            <option value="en">En</option>
            <option value="it">It</option>
          </select>

          <i>
            <FontAwesomeIcon
              className="fa-user"
              icon={faUser}
              size="xs"
              onClick={handleUserPopover}
            />
          </i>
          <span id="userProfile_popover" onClick={handleUserPopover}>
            {state.meData.length !== 0 &&
              state.meData.firstName + " " + state.meData.lastName}
            <br />
            <p className="user_email">
              {state.meData.length !== 0 && state.meData.email}
            </p>
          </span>
        </div>
        <UncontrolledPopover
          className="user_popover"
          trigger="legacy"
          placement="bottom"
          target="userProfile_popover"
          isOpen={state.showUserPopover}
        >
          <PopoverHeader id="user_popover_header">
            {t("my profile")}
          </PopoverHeader>
          <PopoverBody style={{ background: "white" }}>
            <Row>
              <Col sm="3" style={{ alignSelf: "center", textAlign: "end" }}>
                <FontAwesomeIcon
                  className="popover_user_icon"
                  icon={faUser}
                  size="xs"
                  style={{
                    color: "rgb(0, 144, 209)",
                    fontSize: "24px",
                    fontWeight: "solid",
                    height: "24px",
                    lineHeight: "15px",
                    textAlign: "center",
                    width: "24px",
                  }}
                />
              </Col>
              <Col sm="9" style={{ padding: "10px 0" }}>
                <p className="popover_username">
                  {state.meData.length !== 0 &&
                    state.meData.firstName + " " + state.meData.lastName}
                </p>
                <p className="popover_email">
                  {state.meData.length !== 0 && state.meData.email}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="6" style={{ textAlign: "center" }}>
                <button
                  className="popover_modify_button"
                  onClick={() => {
                    showEditProfileModal();
                    handleUserPopover();
                  }}
                >
                  <FontAwesomeIcon className="modify_icon" icon={faEdit} />
                  {t("edit")}
                </button>
              </Col>
              <Col sm="6" style={{ textAlign: "center" }}>
                <button
                  className="popover_exit_button"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  {t("exit")}
                </button>
              </Col>
            </Row>
          </PopoverBody>
        </UncontrolledPopover>
      </div> */}

      <Container fluid={true}>
        <Row className="main-content">
          <Col sm="2" md="4" lg="3" xl="2" className="left-sidebar">
            <div className="content">
              <p>{t("Data Manager")}</p>

              <button className="create_new" onClick={showModal}>
                {t("create new")}
              </button>

              <hr className="hr" />

              {leftsideMenu.map((menu) => (
                <Row
                  className={`menu-items ${
                    state.activeLeftNavbarMenu === menu.label ? "active" : ""
                  } ${menu.disable ? "disable" : ""}`}
                  key={menu.label}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      activeLeftNavbarMenu: menu.label,
                    }))
                  }
                  style={{
                    display:
                      menu.label === "Recent" && menu.disable === true
                        ? "none"
                        : "flex",
                    gridTemplateColumns: "24px 2fr 30px",
                    // display: "none",
                  }}
                >
                  <Col sm="1" md="2" lg="1">
                    <FontAwesomeIcon icon={menu.icon} size="1x" />
                  </Col>
                  <Col sm="10" md="9" lg="10">
                    {menu.text}
                  </Col>
                  {/* <Col sm="1" md="1" lg="1" style={{ marginLeft: "-13px" }}> */}
                    {/* <Badge color="secondary">2</Badge> */}
                  {/* </Col> */}
                  {/* (deletedProjects !== undefined && deletedProjects.length === 0) ? "#EAF0F6" : "#9EA6AB" */}
                </Row>
              ))}
            </div>
          </Col>

          <Col sm="10" md="8" lg="9" xl="10" className="main-panel">
            <div className="main-panel-content">
              {
                // props.projects.length === 0 ? (
                //   <DashboardNoProjects />
                // ) : (
                {
                  "All Projects":
                      props.projects && props.projects.length === 0? (
                      <DashboardNoProjects />
                    ) : (
                      <DashboardAllProjects
                        hasNewSpreadsheet={state.createdNewSpreadsheet}
                        reloadDashboard={getAllProjects}
                      />
                    ),
                  Recent: (
                    <DashboardRecentProjects reloadDashboard={getAllProjects} dashboardStore={[state, setState]}/>
                  ),
                  Favourites: (
                    <DashboardFavourites reloadDashboard={getAllProjects} dashboardStore={[state, setState]}/>
                  ),
                  Archived: (
                    <DashboardArchived reloadDashboard={getAllProjects} dashboardStore={[state, setState]}/>
                  ),
                  Deleted: (
                    <DashboardDeleted reloadDashboard={getAllProjects} dashboardStore={[state, setState]}/>
                  ),
                }[state.activeLeftNavbarMenu]
              }
            </div>
          </Col>
        </Row>
      </Container>

      {/* <CreateNewProject showModal={state.showNewProjectModal}/> */}
      <Modal isOpen={state.showNewProjectModal} autoFocus={false}>
        <ModalHeader toggle={handleCancelNewProject}>
          <span className="modal-title">{t("create new spreadsheet")}</span>
        </ModalHeader>
        <ModalBody>
          <p className="name">
            <strong>{t("name your spreadsheet")}</strong>
          </p>
          <small>{t("name your spreadsheet info")}</small>
          <div style={{ height: "8px" }}></div>
          <form onSubmit={createSpreadsheet}>
            <p>
              <input
                type="text"
                placeholder={t("spreadsheet name")}
                className="spreadsheet-name"
                value={state.spreadsheetName}
                onChange={handleSpreadsheetNameChange}
                autoFocus={true}
              />
            </p>
          </form>
          {state.errorMessage !== "" && (
            <Alert color="danger" isOpen={state.openAlert}>
              {state.errorMessage}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <button className="discard-button" onClick={handleCancelNewProject}>
            {t("discard")}
          </button>
          <button className="create-button" type="submit" onClick={createSpreadsheet}>
            {t("create")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    projects: state.dashboard.projects && state.dashboard.projects.items,
  };
}

export default connect(mapStateToProps)(Dashboard);
