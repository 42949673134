import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from "react-redux";
import axios from "../../axios";
import { fetchAllProjects } from "../../actions";
import '../../styles/Login.css';

const RegisterDrag = () => {

    const dispatch = useDispatch();

    let history = useHistory()

    const [state, setState] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        nameError: false,
        usernameError: false,
        emailError: false,
        passwordError: false,
        passwordConfirmError: false,
    })

    const onNameChange = (e) => setState(state => ({ ...state, name: e.target.value, nameError: false }));
    const onUsernameChange = (e) => setState(state => ({ ...state, username: e.target.value, usernameError: false }));
    const onEmailChange = (e) => setState(state => ({ ...state, email: e.target.value, emailError: false }));
    const onPasswordChange = (e) => setState(state => ({ ...state, password: e.target.value, passwordError: false }));
    const onPasswordConfirmChange = (e) => setState(state => ({ ...state, passwordConfirm: e.target.value, passwordConfirmError: false }));

    const signUserUp = () => {

        if(state.name === ''){
            setState(state => ({ ...state, nameError: true }));
        } else {
            setState(state => ({ ...state, nameError: false }));
        }
        if(state.username === ''){
            setState(state => ({ ...state, usernameError: true }));
        }else {
            setState(state => ({ ...state, usernameError: false }));
        }
        if(state.email === ''){
            setState(state => ({ ...state, emailError: true }));
        }else {
            setState(state => ({ ...state, emailError: false }));
        }
        if(state.password === ''){
            setState(state => ({ ...state, passwordError: true }));
        }else {
            setState(state => ({ ...state, passwordError: false }));
        }
        if(state.passwordConfirm === ''){
            setState(state => ({ ...state, passwordConfirmError: true }));
        }else {
            setState(state => ({ ...state, passwordConfirmError: false }));
        }

        if(!state.nameError && !state.usernameError && !state.emailError && !state.passwordError && !state.passwordConfirmError) {
            if(state.passwordConfirm === state.password) {
                axios.post('/api/auth/register',{
                    username: state.username,
                    email: state.email,
                    firstName: state.name,
                    lastName: state.name,
                    company: "",
                    password: state.password,
                    passwordConfirmation: state.passwordConfirm,
                    defaultLanguageCode: "en-GB"
                })
                    .then(async res => {
                        localStorage.setItem("access", res.data.accessToken);
                        localStorage.setItem("expiresIn", res.data.expiresIn);
                        //await dispatch(fetchAllProjects());
                        //setState(state => ({...state, loading: false}))
                        history.push('/')
                    })
                    .catch((err) => {
                        console.log(err)
                        // setState(state => ({...state, invalidPassword: true, invalidUsername: true, loading: false }));
                    })
            }else {
                alert("Password Confirmation does not match password.");
            }
        }

        }

    return(
        <div>
            <div className="login_page_container">
                <div className="login_container">
                    <div className="teamsystem_id_container">
                        <h2 className="login_title">Registrati a TeamSystem Drag</h2>
                    </div>
                    <div className="login_form_container">
                        
                        <p className="input_label" style={{ marginTop: '1rem'}}>Nome Cognome</p>
                        <input className="login_input" style={{ border: state.nameError && "1px solid red" }} type="text" value={state.name} onChange={onNameChange} placeholder="Inserisci Nome"/>

                        <p className="input_label" style={{ marginTop: '1rem'}}>Nome utente</p>
                        <input className="login_input" style={{ border: state.usernameError && "1px solid red" }} type="text" value={state.username} onChange={onUsernameChange} placeholder="Inserisci Nome Utente"/>
                       
                        <p className="input_label" style={{ marginTop: '1rem'}}>Email</p>
                        <input className="login_input" style={{ border: state.emailError && "1px solid red" }} type="text" value={state.email} onChange={onEmailChange} placeholder="Inserisci l'Email che vuoi associare al tuo account"/>

                        <p className="input_label" style={{marginTop: '1rem'}}>Password</p>
                        <input className="login_input" type="password" style={{ border: state.passwordError && "1px solid red" }} value={state.password} onChange={onPasswordChange} placeholder="Inserisci password"/>
                        
                        <p className="input_label" style={{marginTop: '1rem'}}>Conferma Password</p>
                        <input className="login_input" type="password" style={{ border: state.passwordConfirmError && "1px solid red" }} value={state.passwordConfirm} onChange={onPasswordConfirmChange} placeholder="Inserisci nuovamente la password"/>

                        <div className="register_buttons_row">
                            <button className="redirect_to_signup_Button" onClick={() => {history.goBack()}}>Torna indietro</button>
                            <button className="access_team_button" onClick={signUserUp}>Registra il tuo Account</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterDrag;