import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faAngleDown,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { Alert, Badge, Collapse, Row, Col } from "reactstrap";
import axios from "../../axios";

import { ProjectItem } from "./Project.Item";
import "../../styles/DashboardRecentProjects.css";

const DashboardRecentProjects = ({ languageSelected }) => {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    openTodayCollapse: true,
    openYesterdayCollapse: false,
    openLastWeekCollapse: false,
    projects: [],
    todayProjects: [],
    yesterdayProjects: [],
    lastWeekProjects: [],
  });

  React.useEffect(() => {
    getProjects();
  }, []);

  React.useEffect(() => {
    getToday();
  }, [state.projects]);

  const getProjects = () => {
    // dispatch(fetchAllProjects())
    axios.get("/api/projects?isDeleted=false").then((res) => {
      setState((state) => ({ ...state, projects: res.data.items }));
    });
  };

  const getToday = () => {
    // "2021-05-10T15:52:09.724Z"
    let filterToday;
    let filterYesterday;
    let filterLastWeek;
    const date = new Date(Date.now());
    const todayDate = date.toISOString().split("T")[0];
    const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
    const lastWeek =  ((d) => new Date(d.setDate(d.getDate() - 7)))(new Date())
    if (state.projects.length !== 0) {
      filterToday = state.projects.filter(
        (project) => project.updatedAt.split("T")[0] === todayDate
      );
      filterYesterday = state.projects.filter(
        (project) =>
          project.updatedAt.split("T")[0] ===
          yesterday.toISOString().split("T")[0]
      );
      filterLastWeek = state.projects.filter(
        (project) =>
          project.updatedAt.split("T")[0] === lastWeek.toISOString().split("T")[0] || (project.updatedAt.split("T")[0] > lastWeek.toISOString().split("T")[0] && project.updatedAt.split("T")[0] < todayDate)
      );
      setState((state) => ({
        ...state,
        todayProjects: filterToday,
        yesterdayProjects: filterYesterday,
        lastWeekProjects: filterLastWeek
      }));
    }
  };

  const toggleTodayProjects = () => {
    setState((state) => ({
      ...state,
      openTodayCollapse: !state.openTodayCollapse,
    }));
  };
  const toggleYesterdayProjects = () => {
    setState((state) => ({
      ...state,
      openYesterdayCollapse: !state.openYesterdayCollapse,
    }));
  };

  const toggleLastWeekProjects = () => {
    setState((state) => ({
      ...state,
      openLastWeekCollapse: !state.openLastWeekCollapse,
    }));
  };

  return (
    <div className="main_wrapper">
      <Row className="spreadsheet_top_info">
        <Col sm="7">
          <Row>
            <Col sm="2" md="1" lg="1" className="icon_column"><FontAwesomeIcon size="lg" className="clock_icon" icon={faClock}/></Col>
            <Col sm="10" md="11" lg="11" className="title_column">
              <h4 className="all_spreadsheet_title">{t("Recent")}</h4>
              <p className="all_spreadsheet_subtitle">{state.projects.length} {t("all in folder")}</p>
            </Col>
          </Row>
        </Col>
        <Col sm="5">
          {/* <input /> */}
        </Col>
      </Row>

      <Row className="today_collapse_row" style={{background: state.openTodayCollapse ? "white" : "#F2F5F8"}}>
        <div className="recent-projects-parent">
          <span onClick={toggleTodayProjects} className="collapse-titles">
            <FontAwesomeIcon icon={state.openTodayCollapse ? faAngleDown : faAngleRight}/>{" "}
            <span className="collapse_title">
              {t("today")}{" "}
              <Badge color="secondary">
                {state.todayProjects === [] ||
                state.todayProjects === undefined
                  ? 0
                  : state.todayProjects.length}
              </Badge>
            </span>
          </span>
          <Collapse isOpen={state.openTodayCollapse}>
            {state.todayProjects === [] ||
            state.todayProjects === undefined ? (
              <Alert color="info" style={{margin: "13px 5px"}}>No Projects for today</Alert>
            ) : (
              <div className="projects__wrapper">
                <div className="projects__container">
                  {state.todayProjects.map((project) => (
                    <ProjectItem
                      key={`project-item-${project.id}`}
                      project={project}
                      languageSelected={languageSelected}
                      getProjects={getProjects}
                    />
                  ))}
                </div>
              </div>
            )}
          </Collapse>
        </div>
      </Row>

      <Row className="today_collapse_row" style={{background: state.openYesterdayCollapse ? "white" : "#F2F5F8"}}>
        <div className="recent-projects-parent">
          <span onClick={toggleYesterdayProjects} className="collapse-titles">
            <FontAwesomeIcon icon={state.openYesterdayCollapse ? faAngleDown : faAngleRight} />{" "}
            <span className="collapse_title">
              {t("yesterday")}{" "}
              <Badge color="secondary">
                {state.yesterdayProjects === [] ||
                state.yesterdayProjects === undefined
                  ? 0
                  : state.yesterdayProjects.length}
              </Badge>
            </span>
          </span>
          <Collapse isOpen={state.openYesterdayCollapse}>
            {state.yesterdayProjects.length === 0 ||
            state.yesterdayProjects === undefined ? (
              // <DashboardNoProjects />
              <Alert color="info" style={{margin: "13px 5px"}}>No Project updated yesterday</Alert>
            ) : (
              <div className="projects__wrapper">
                <div className="projects__container">
                  {state.yesterdayProjects.map((project) => (
                    <ProjectItem
                      key={`project-item-${project.id}`}
                      project={project}
                      languageSelected={languageSelected}
                      getProjects={getProjects}
                    />
                  ))}
                </div>
              </div>
            )}
          </Collapse>
        </div>
      </Row>

      <Row className="today_collapse_row" style={{background: state.openLastWeekCollapse ? "white" : "#F2F5F8"}}>
        <div style={{padding: '19px'}}>
          <span onClick={toggleLastWeekProjects} className="collapse-titles">
            <FontAwesomeIcon icon={state.openLastWeekCollapse ? faAngleDown : faAngleRight} />{" "}
            <span className="collapse_title">
              {t("last week")}{" "}
              <Badge color="secondary">
                {state.lastWeekProjects === [] ||
                state.lastWeekProjects === undefined
                  ? 0
                  : state.lastWeekProjects.length}
              </Badge>
            </span>
          </span>
          <Collapse isOpen={state.openLastWeekCollapse}>
            {state.lastWeekProjects.length === 0 ||
            state.lastWeekProjects === undefined ? (
              // <DashboardNoProjects />
              <Alert color="info" style={{margin: "13px 5px"}}>No Project updated last week</Alert>
            ) : (
              <div className="projects__wrapper">
                <div className="projects__container">
                  {state.lastWeekProjects.map((project) => (
                    <ProjectItem
                      key={`project-item-${project.id}`}
                      project={project}
                      languageSelected={languageSelected}
                      getProjects={getProjects}
                    />
                  ))}
                </div>
              </div>
            )}
          </Collapse>
        </div>
      </Row>
    </div>
  );
};

export default DashboardRecentProjects;
