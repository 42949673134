import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import TsLogo from "../../images/LOGO_TEAMSYSTEM.png";
import "../../styles/TeamsystemPass.css";

const TSRegister = () => {
  return (
    <div>
      <div className="teamsystem_container">
        <div>
          <img src={TsLogo} alt="TS_Logo" width="240px" />
        </div>

        <div>
          <h2 className="ts_title">
            <small>Registrati</small>
          </h2>
        </div>

        <div className="ts_linear_gradient"></div>

        <div>
        <div className="ts_buttons_row">
            <input className="ts_email_input" placeholder="Nome"/>
            <input className="ts_email_input" placeholder="Cognome" />
        </div>

          <input className="ts_email_input" placeholder="Conferma indirizzo email"  style={{ marginTop: '1rem' }} />

          <input className="ts_email_input" placeholder="Password"  style={{ marginTop: '1rem' }} />

          <input className="ts_email_input" placeholder="Conferma password"  style={{ marginTop: '1rem' }} />

        </div>

        <div>
            <p className="ts_bold_text">E' una questione di fiducia!</p>
            <p className="ts_paragraph"> Leggi la nostra informativa sulla privacy e scegli i consensi che desideri rilasciare a Teamsystem. Potrai sempre cambiare la tua scelta e opporti al trattamento dei tuoi dati per l'invio di comunicazioni commerciali per le finalità di interesse legittimo descritte nell’informativa contattandoci all’indirizzo privacy@teamsystem.com </p>
            <p className="ts_paragraph">Vorremmo utilizzare i tuoi dati personali, inclusi quelli relativi all'utilizzo del sito e dei prodotti e servizi TeamSystem, per aggiornarti via email, posta, messaggi istantanei, chat o contattarti al telefono su novità e offerte commerciali di prodotti e servizi TeamSystem ovvero per le altre iniziative di marketing indicate nell'informativa </p>
            <input type="checkbox" id="ts_agree" value="agree"/>
            <label for="ts_agree" className="ts_checkbox_label">Sono d'accordo</label>
        </div>

        <div className="ts_linear_gradient"></div>

        <div className="ts_buttons_row">
          <button className="ts_green_button">
            <FontAwesomeIcon
              icon={faHome}
              color="#ffffff"
              style={{ width: "24px"}}
            />
            Inizio
          </button>
          <button className="ts_blue_button">Procedi</button>
        </div>
      </div>
    </div>
  );
};

export default TSRegister;
