import {
    SETUP_ACTUAL_PROJECT,
    SET_FILE_FOR_RIGHT_PANEL,
    CLOSE_RIGHT_PANEL,
    OPEN_VIEWER,
    CLOSE_VIEWER,
    ADD_TAG_TO_FILE,
    DELETE_TAG_FROM_FILE,
    ADD_FILE_TO_PROJECT,
    MARK_TAG_AS_HIGHLIGHTED
} from "../actions/types";

export default (state = {}, action) => {
    switch (action.type){
        case SETUP_ACTUAL_PROJECT:
            return{
                ...state,
                project_files: action.payload.files,
                project_id: action.payload.project_id
            }
        case SET_FILE_FOR_RIGHT_PANEL:
            return{
                ...state,
                showRightPanel: true,
                activeFileRightPanel: action.payload,
                activeFile: action.fileObject,
                tags: action.payload.tags
            }
        case CLOSE_RIGHT_PANEL:
            return{
                ...state,
                showRightPanel: false,
                openViewer: false
            }
        case OPEN_VIEWER:
            return{
                ...state,
                openViewer: true
            }
        case ADD_TAG_TO_FILE:
            return{
                ...state,
                tags: [...state.tags, action.payload]
            }
        case DELETE_TAG_FROM_FILE:
            return{
                ...state,
                tags: state.tags.filter(tag => tag._id !== action.payload)
            }
        case CLOSE_VIEWER:
            return{
                ...state,
                openViewer: false
            }
        case ADD_FILE_TO_PROJECT:
            return{
                ...state,
                project_files: [...state.project_files, action.payload]
            }
        case MARK_TAG_AS_HIGHLIGHTED:
            return{
                ...state,
                highlightedTag: action.payload
            }
        default:
            return state
    }
}
