import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { fetchAllProjects } from "../actions";
import Dashboard from "./Dashboard";
import { faInfo, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';

import Login from '../containers/Login/Login';
import TeamsystemLogin from '../containers/Login/TeamSystemLogin';
import RecoverPassword from '../containers/Login/RecoverPassword';
import TSForgotPass from "../containers/Login/ForgotPassTS";
import TSRegister from './Login/TsRegister';
import Spreadsheet from "../components/Spreadsheet/Spreadsheet";
import Error404 from "./Errors/Error404";
import Error401 from "./Errors/Error401";
import Error500 from "./Errors/Error500";
import 'bootstrap/dist/css/bootstrap.min.css';
import ProtectedRoute from "../components/ProtectedRoute";
import AuthenticationParent from "./Login/AuthenticationParent";


const TestNotification = ({ closeToast, toastProps }) => {
    return (
        <div style={{border: "1px solid red",display: 'grid',gridTemplateColumns: '60px 1fr'}}>
            {/* <button onClick={closeToast}> */}
                <div style={{width: '24px', height: '24px', borderRadius: '50%', background: 'red', justifySelf: 'center'}}>
                    <FontAwesomeIcon icon={faInfo} color='white' style={{width: '24px'}}/>
                </div>
                <div>
                    <p style={{fontSize: '16px', fontWeight: 500, color: 'rgb(21, 41, 53)'}}>Error!</p>
                </div>
            {/* </button> */}
        </div>

    )
}

const App = (props) => {

    const dispatch = useDispatch();



    useEffect(async () => {
        //await dispatch(fetchAllProjects());

        // setTimeout(() => {
        //     toast(TestNotification)
        // }, 2000)
    }, []);

    return(
        <>
            {/*{props.projects && (*/}
                <Switch>
                    <Route path='/' exact component={Dashboard} />
                    <Route path='/project/:id' exact component={Spreadsheet} />
                    <Route path='/login' exact component={AuthenticationParent} />
                    <Route path='/login-teamsystem' exact component={AuthenticationParent} />
                    <Route path='/recover-password' exact component={AuthenticationParent} />
                    <Route path="/register-drag" exact component={AuthenticationParent}/>
                    <Route path="/register-teamsystem" exact component={TSRegister}/>
                    <Route path="/change-password" exact component={AuthenticationParent}/>
                    <Route path="/change-password-teamsystem" exact component={TSForgotPass}/>
                    <Route path="/success-password" exact component={AuthenticationParent}/>
                    <Route path='/error-404' exact component={Error404}/>
                    <Route path='/error-401' exact component={Error401}/>
                    <Route path='/error-500' exact component={Error500} />
                    <Route path="*" component={Error404}/>
                </Switch>
            <ToastContainer hideProgressBar={true} autoClose={3000}/>
        </>
    )
}

function mapStateToProps(state){
    return{
        projects: state.dashboard.projects
    }
}

export default connect(mapStateToProps)(App);