import React from "react";
import { useHistory } from "react-router";
import { FormGroup, Label, Input } from "reactstrap";

import "../../styles/Login.css";
import digitalLogo from "../../images/digital_logo.svg";

const TeamsystemLogin = () => {
  let history = useHistory();

  return (
    <div>
      {/*<div className="login-top-Nav">*/}
      {/*  <div>*/}
      {/*    <p className="login_logo">*/}
      {/*      <span className="data-text">Data</span>*/}
      {/*      <span className="manager-text">Manager</span>*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="login_page_container recovery_page">
        <div className="login_container">
          <div className="teamsystem_id_container">
            {/* <img className="digital_logo" src={digitalLogo} alt="digital logo" /> */}

            <h2 className="login_title">Hai dimenticato la tua password?</h2>
            <p className="teamsystem-login_subtitle">
              Inserisci l’Email dell'utenza di cui non ti ricordi la password,
              ti invieremo una Email per poterla resettare.
            </p>
          </div>
          <div className="login_form_container">
            <p className="input_label">Email</p>
            <input
              className="login_input"
              type="email"
              placeholder="Inserisci Email"
            />

            <div className="register_buttons_row">
            <button
                className="redirect_to_signup_Button"
                onClick={() => history.goBack()}
              >
                Torna indietro
              </button>
              <button className="access_team_button">
                Recupera Password
              </button>
              
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default TeamsystemLogin;
