import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, translate, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";
import { fetchAllProjects } from "../../actions";

import { ProjectItem } from "./Project.Item";

import "../../styles/DashboardAllProjects.css";
import axios from "../../axios";

const DashboardAllProjects = ({ languageSelected, hasNewSpreadsheet, onNoProjectCreate, reloadDashboard }) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    searchInput: "",
    filteredProjects: [],
    projects: [],
  });

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.dashboard);

  React.useEffect(() => {
    getProjects();
  }, [hasNewSpreadsheet]);

  const getProjects = () => {
    axios.get("/api/projects?isDeleted=false").then((res) => {
      setState((state) => ({ ...state, projects: res.data.items }));
    });
  };

  const setSearchInput = (searchInput) => {
    setState((state) => ({ ...state, searchInput }));
  };

  const searchAllProjects = () => {
    axios
      .get(`api/projects?search=${state.searchInput}`)
      .then((res) => {
        setState((state) => ({ ...state, projects: res.data.items }));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="main__wrapper">
        {/* search on top  */}
        <Row>
          <Col sm="4" className="submenu_all_spreadsheets">
            <div className="file_icon">
              <svg
                style={{ width: "24px", height: "24px" }}
                fill="rgb(0, 80, 117)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M80 240v176c0 8.84 7.16 16 16 16h192c8.84 0 16-7.16 16-16V240c0-8.84-7.16-16-16-16H96c-8.84 0-16 7.16-16 16zm128 32h64v48h-64v-48zm0 80h64v48h-64v-48zm-96-80h64v48h-64v-48zm0 80h64v48h-64v-48zM369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95z" />
              </svg>
            </div>
            <div>
              <h4 className="spreadsheet__title">{t("All Projects")}</h4>
              <p className="spreadsheet__subtitle">
                {state.projects && state.projects.length}{" "}
                {t("spreadsheet in folders")}
              </p>
            </div>
          </Col>
          <Col sm="8">
            <div className="input-group">
              <div className="form-outline">
                <input
                  type="search"
                  className="form-control"
                  id="searchInput"
                  value={state.searchInput}
                  onChange={({ target: { value } }) => setSearchInput(value)}
                  placeholder={t("search")}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                id="search_button"
                onClick={searchAllProjects}
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </Col>
        </Row>

        {/* projects */}
        {/*{state.projects.length === 0 ? (
          <DashboardNoProjects languageSelected={languageSelected} />
        ) : (*/}
          <div className="projects__wrapper" style={{height: "72vh", overflowY: "scroll"}}>
            <div className="projects__container">
              {state.projects.map((project) => (
                <ProjectItem
                  key={`project-item-${project.id}`}
                  project={project}
                  languageSelected={languageSelected}
                  getProjects={getProjects}
                  reloadDashboard={reloadDashboard}
                />
              ))}

              {/* {projects.projects && projects.projects.items.map((project) => (
                                <ProjectItem
                                    key={`project-item-${project.id}`}
                                    project={project}
                                    languageSelected={languageSelected}
                                    getProjects={projects}
                                />
                            ))}   */}
            </div>
          </div>
        
      </div>
    </>
  );
};

export default DashboardAllProjects;
