import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Modal,
  Row,
  Col,
  Container,
} from "reactstrap";
import { useDispatch } from "react-redux";

import axios from "../../axios";
import { addNewProject } from "../../actions";
import NoProjectsImage from "../../images/dashboard_no_projects.svg";
import { NotifyError, NotifyInfo, NotifySuccess, NotifyWarning } from "../Notifications";
import "../../styles/DashboardNoProjects.css";

const DashboardNoProjects = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    languageSelected: "en",
    showNewProjectModal: false,
    spreadsheetName: "",
    openAlert: false,
    errorMessage: "",
    createdNewSpreadsheet: false,
  });

  const showModal = () => {
    setState((state) => ({
      ...state,
      showNewProjectModal: true,
    }));
  };

  const handleCancelNewProject = () => {
    setState((state) => ({
      ...state,
      showNewProjectModal: false,
      spreadsheetName: "",
    }));
  };

  const handleSpreadsheetNameChange = (e) => {
    e.persist();
    setState((state) => ({
      ...state,
      spreadsheetName: e.target.value,
    }));
  };

  const createSpreadsheet = (e) => {
    e.preventDefault();
    let postData = {
      name: state.spreadsheetName,
      description: "",
      isDeleted: false,
      isFavorite: false,
      isArchived: false,
      data: {},
    };
    axios
      .post("/api/projects", postData)
      .then((response) => {
        dispatch(addNewProject(response.data));
        setState((state) => ({
          ...state,
          showNewProjectModal: false,
          spreadsheetName: "",
          createdNewSpreadsheet: true,
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          errorMessage: error.message,
          openAlert: true,
        }));
        NotifyError("Oh no! Something happened!")
      });
  };

  return (
    <div className="no_project_mainWrapper">
      <Row>
        <Col md="7" sm="10" className="titles_column">
          <h1 className="dashboard_noProjects_first-title">{t("no data")}</h1>
          <h3 className="dashboard_noProjects_second-title">
            {t("start manage data")}
          </h3>
          <h3 className="dashboard_noProjects_second-title">
            {t("suported files")} PDF, EXCEL, JSON and XML
          </h3>
          <button className="create_button" onClick={showModal}>
            <FontAwesomeIcon icon={faPlus} transform="shrink-2 left-10" />
            {t("create new")}
          </button>
        </Col>
        <Col md="5" sm="2"></Col>
      </Row>
      {/* </div> */}
      {/* <div> */}
      <Row>
        <Col sm="12" style={{ textAlign: "center" }}>
          <img className="no_project_image" src={NoProjectsImage} alt="" />
        </Col>
      </Row>

      <Modal isOpen={state.showNewProjectModal} autoFocus={false}>
        <ModalHeader toggle={handleCancelNewProject}>
          <span className="modal-title">{t("create new spreadsheet")}</span>
        </ModalHeader>
        <ModalBody>
          <p className="name">
            <strong>{t("name your spreadsheet")}</strong>
          </p>
          <small>{t("name your spreadsheet info")}</small>
          <div style={{ height: "8px" }}></div>
          <form onSubmit={createSpreadsheet}>
            <p>
              <input
                  type="text"
                  placeholder={t("spreadsheet name")}
                  className="spreadsheet-name"
                  value={state.spreadsheetName}
                  onChange={handleSpreadsheetNameChange}
                  autoFocus={true}
              />
            </p>
          </form>
          {state.errorMessage !== "" && (
            <Alert color="danger" isOpen={state.openAlert}>
              {state.errorMessage}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <button className="discard-button" onClick={handleCancelNewProject}>
            {t("discard")}
          </button>
          <button className="create-button" type="submit" onClick={createSpreadsheet}>
            {t("create")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DashboardNoProjects;
