import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translationsFiles/en";
import it from "./translationsFiles/it";

i18n.init({
    // we init with resources
    resources: {
      en: en,
      it: it
    },
    fallbackLng: "it",
    debug: false,
  
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  
    keySeparator: false, // we use content as keys
  
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
  
    react: {
      wait: true
    }
  });
  
  export default i18n;