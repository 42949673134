import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import App from "./containers/App";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import { I18nextProvider } from "react-i18next";
import "./styles/index.css";

import i18n from "./i18n";
import reactDebugHooks from "react-debug-hooks";
import { ParentApp } from "@mondora/microfrontends";

reactDebugHooks(React);

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store = createStore(
  reducers,
  {
    dashboard: { activeLeftNavbarMenu: "All Projects" },
    language: "en",
    spreadsheet: {
      showRightPanel: false,
      showViewer: false,
      highlightedTag: "",
    },
  },
  composeEnhancers(applyMiddleware(reduxThunk))
);

const ParentDataContext = React.createContext();
export const useParentDataContext = () => React.useContext(ParentDataContext);

let parentData;
export const getParentData = () => parentData;

const parentApp = new ParentApp({
  parentOrigin: process.env.REACT_APP_PARENT_ORIGIN,
  onLaunch(launchData) {
    parentData = launchData;
    parentData?.settings?.language &&
      i18n.changeLanguage(parentData.settings.language);

    ReactDOM.render(
      //  <React.StrictMode>
      <ParentDataContext.Provider value={launchData}>
        <Provider store={store}>
          <BrowserRouter>
            <I18nextProvider i18n={i18n}>
              <App i18n={i18n} />
            </I18nextProvider>
          </BrowserRouter>
        </Provider>
      </ParentDataContext.Provider>,
      //  </React.StrictMode>
      document.getElementById("root")
    );
  },
});

// ReactDOM.render(
//   //  <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <I18nextProvider  i18n={i18n}>
//           <App i18n={i18n}/>
//         </I18nextProvider>
//       </BrowserRouter>
//     </Provider>
//   //  </React.StrictMode>
//   ,
//   document.getElementById("root")
// );
