import React from 'react';
import { Input, Label } from 'reactstrap';
import { useTranslation } from "react-i18next"

import '../../styles/ProfileSettings.css';
import axios from '../../axios';


const EditProfile = ({dataToChange, enableButton}) => {

    const { t } = useTranslation();

    const [state, setState] = React.useState({
        nameValue: '',
        lastname: "",
        emailValue: "",
        companyValue: '',
        usernameValue: '',
        profileData: [],
        emailInputChanged: false
    })

    React.useEffect(() => {
        getProfileInfo()
    }, []);

    React.useEffect(() => {
        let dataToPost = {
            firstName: state.nameValue,
            lastName: state.lastname,
            company: state.companyValue == undefined ? "" : state.companyValue
        }
        let changedEmail = {
            email: state.emailValue
        };
        dataToChange(dataToPost, "/api/profile")
        // state.emailInputChanged && dataToChange(changedEmail, "api/profile/emails")
    }, [state.nameValue, state.lastname, state.companyValue, state.emailInputChanged])

    const getProfileInfo = () => {
        axios.get("/api/me")
        .then(res => setState(state => ({
            ...state, 
            profileData: res.data, 
            nameValue: res.data.firstName !== "" ? res.data.firstName : "", 
            lastname: res.data.lastName !== "" ? res.data.lastName : "",
            emailValue: res.data.email !== "" ? res.data.email : "", 
            companyValue: res.data.company !== "" ? res.data.company : ""
        })))
        .catch(err => console.log("err", err))
    }

    const changeNameInputValue = (e) => {
        e.persist();
        setState(state => ({ ...state, nameValue: e.target.value }))
        enableButton()
    }
    const changeLastNameInputValue = (e) => {
        e.persist();
        setState(state => ({ ...state, lastname: e.target.value }))
        enableButton()
    }
    const changeCompanyInputValue = (e) => {
        e.persist();
        setState(state => ({ ...state, companyValue: e.target.value }))
        enableButton()
    }

    // const changeEmailInputValue = (e) => {
    //     e.persist();
    //     setState(state => ({ ...state, emailValue: e.target.value, emailInputChanged: true }))
    // }

    return (
        <div>
            <div>
                {/* <Label className="label">
                    <p>{t("fullname")} *</p>
                </Label> */}
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "5px"}}>
                    <div>
                        <Label className="label">
                            <p>{t("name")} *</p>
                        </Label>
                        <Input className="profile_input" value={state.nameValue} type="text" placeholder={state.profileData.length !== 0 ? state.profileData.firstName : t("name")} onChange={changeNameInputValue}/>
                    </div>
                    <div>
                        <Label className="label">
                            <p>{t("surname")} *</p>
                        </Label>
                        <Input className="profile_input" value={state.lastname} type="text" placeholder={state.profileData.length !== 0 ? state.profileData.lastName : t("surname")} onChange={changeLastNameInputValue}/>
                    </div>
                </div>
            </div>

            {/* <div>
                <Label className="label" style={{marginTop: '15px'}}>
                    <p>Email</p>
                </Label>
                <Input className="profile_input" value={state.emailValue} type="text" placeholder={state.profileData.length !== 0 ? state.profileData.email : "Email"} onChange={changeEmailInputValue}/>
            </div> */}

            <div style={{margin: '15px 0'}}>
                <Label className="label">
                    <p>{t("company")}</p>
                </Label>
                <Input className="profile_input" value={state.companyValue} type="text" placeholder={state.profileData.length !== 0 && state.profileData.company ? state.profileData.company : t("company name")} onChange={changeCompanyInputValue}/>
            </div>

            <div style={{marginBottom: '20px'}}>
                <Label className="label">
                <p>{t("username")}</p>
                </Label>
                <Input className="profile_input" disabled type="text" placeholder={state.usernameValue}/>
            </div>
        </div>
    )
}

export default EditProfile;