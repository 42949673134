import React, {useState} from 'react';
import { useHistory } from 'react-router';

import '../../styles/Login.css';
import axios from "../../axios";
import {fetchAllProjects} from "../../actions";
import {useDispatch} from "react-redux";

const TeamsystemLogin = () => {

    let history = useHistory()
    const dispatch = useDispatch();

    const [state, setState] = useState({
        username: '',
        password: '',
        usernameError: false,
        passwordError: false
    })

    const onEmailChange = (e) => setState(state => ({ ...state, username: e.target.value, usernameError: false }));
    const onPasswordChange = (e) => setState(state => ({ ...state, password: e.target.value, passwordError: false }));

    const login = () => {

        if(state.username === '' && state.password === ''){
            setState(state => ({ ...state, usernameError: true, passwordError: true }));
        }else if(state.username === ''){
            setState(state => ({ ...state, usernameError: true }));
        }else if(state.password === ''){
            setState(state => ({ ...state, passwordError: true }));
        }else{
            axios.post('/api/auth/tsd-login',{
                username: state.username,
                password: state.password
            })
                .then(async res => {
                    console.log(res.data)
                    localStorage.setItem("access", res.data.accessToken);
                    await dispatch(fetchAllProjects());
                    setState(state => ({...state, loading: false}))
                    history.push('/')
                })
                .catch(() => {
                    // setState(state => ({...state, invalidPassword: true, invalidUsername: true, loading: false }));
                })
        }
    }

    return(
        <div>
            {/*<div className="login-top-Nav">*/}
            {/*    <div>*/}
            {/*        <p className="login_logo">*/}
            {/*            <span className="data-text">Data</span>*/}
            {/*            <span className="manager-text">Manager</span>*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="login_page_container">
                <div className="login_container">
                    <div className="teamsystem_id_container">
                        <h2 className="login_title">Accedi a TeamSystem Drag</h2>
                        {/* <button className="access_team_button">Accedi tramite TeamSystem ID</button> */}
                        <p className="teamsystem-login_subtitle">Inserisci il tuo TeamSystem ID (TSID) e la tua Password per effettuare l’accesso a TeamSystem Drag.</p>
                    </div>
                    <div className="login_form_container">

                        <p className="input_label">TeamSystem ID</p>
                        <input className="login_input" type="text" style={{ border: state.usernameError && "1px solid red" }} value={state.username} onChange={onEmailChange} placeholder="Inserisci TeamSystem ID"/>

                        <p className="input_label" style={{marginTop: '1rem'}}>Password</p>
                        <input className="login_input" type="password" style={{ border: state.passwordError && "1px solid red" }} value={state.password} onChange={onPasswordChange} placeholder="Inserisci password"/>
                        <p className="forgot_password" onClick={() => history.push('/recover-password')}>Password dimenticata?</p>

                        <button className="access_team_button" onClick={login}>Accedi con TeamSystem ID</button>
                        <button className="no_borders_button" onClick={() => history.push('/register-teamsystem')}>Non hai ancora un Account? Registrati adesso</button>

                        <div className="horizontal_line_div">
                            <hr color="#e4eaf0" style={{width: '100%'}}/>
                            <p className="oppure_text">oppure</p>
                            <hr color="#e4eaf0" style={{width: '100%'}}/>
                        </div>

                        <button className="redirect_to_signup_Button" onClick={() => history.push('/login')}>Accedi o registrati senza TeamSystem ID</button>

                        {/* <p className="teamsystem-login_subtitle">Con TeamSystem ID puoi accedere a diversi applicativi TeamSystem utilizzando la tua email e una password.</p> */}

                        {/* <button className="redirect_to_signup_Button">Registra il tuo TeamSystem ID</button>
                        <button className="access_without_TeamId_Button" onClick={() => history.push('/login')}>Accedi senza TeamSystem ID</button> */}

                    </div>
                </div>

                <div></div>
            </div>
        </div>
    )
}

export default TeamsystemLogin;