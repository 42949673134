import React from "react";
import { Route } from "react-router-dom";
import Login from "./Login";
import '../../styles/AuthenticationParent.css'
import TeamsystemLogin from "./TeamSystemLogin";
import RecoverPassword from "./RecoverPassword";
import RegisterDrag from "./RegisterDrag";
import ChangePass from './ChangePass';
import PasswordChanged from "./PasswordChanged";

const AuthenticationParent = () => {
    return(
        <React.Fragment>
            <div className="login-top-Nav">
                <div>
                    <p className="login_logo">
                        <span className="data-text">Data</span>
                        <span className="manager-text">Manager</span>
                    </p>
                </div>
            </div>
            <div className="auth-content-parent">
                <Route path='/login' exact component={Login} />
                <Route path='/login-teamsystem' exact component={TeamsystemLogin} />
                <Route path='/recover-password' exact component={RecoverPassword} />
                <Route path="/register-drag" exact component={RegisterDrag}/>
                <Route path="/change-password" exact component={ChangePass}/>
                <Route path="/success-password" exact component={PasswordChanged}/>
            </div>

        </React.Fragment>
    );
};

export default AuthenticationParent