import React, { Component } from "react";
import { connect } from "react-redux";
import { addTagToFile, closeViewer } from "../../actions";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
} from "reactstrap";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FileViewer from "react-file-viewer";
import axios from "../../axios";
import {
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import ExcelViewer from "./ExcelViewer";

class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      jsonData: "",
      xmlData: "",
      showTagPopover: false,
      selectedText: "",
      tag: "",
      tagLine: "",
      nameTaken: false,
      isFileOpened: false,
    };

    this.pdfContent = React.createRef();
  }

  changePage = (offset) => {
    this.setState({
      pageNumber: this.state.pageNumber + offset,
    });
  };

  showPreviousPage = () => {
    this.changePage(-1);
  };

  setZoomValue = (val) => this.setState({ fileViewerZoom: val });
  showNextPage = () => {
    this.changePage(1);
  };

  componentDidMount() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  componentDidUpdate() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  removeTextLayerOffset = () => {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  };

  readJson = (file) => {
    var reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        jsonData: e.target.result,
      });
    };

    return reader.readAsText(file);
  };

  readXml = (file) => {
    var reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        xmlData: e.target.result,
      });
    };

    return reader.readAsText(file);
  };

  getSelectedText = () => {
    const selectedText = window.getSelection().toString();

    const s = window.getSelection();
    const oRange = s.getRangeAt(0); //get the text range
    const tagLine = oRange.startContainer.textContent;
    const oRect = oRange.getBoundingClientRect();

    // if(parseInt(selectedText)){
    if (selectedText !== "") {
      this.setState({
        showTagPopover: true,
        selectedText: selectedText,
        tagLine: tagLine,
      });

      /* setTimeout(() => {
                const variableAssigned = prompt("Put note to identify this number");

                const { spread } = this.props;

                this.props.addTagToFile(variableAssigned, selectedText, tagLine)

                spread.getActiveSheet().addCustomName(`${variableAssigned}`,`=${selectedText}`, 0, 0);

                this.props.saveSpread(spread);

                //this.setState({ showTagTooltip: true })

            }, 1000);*/
    }
  };

  confirmNewTag = () => {
    const { spread } = this.props;

    axios
      .post("/api/tags", {
        projectId: this.props.spreadsheet.project_id.toString(),
        fileId:
          this.props.spreadsheet.activeFileRightPanel.fileObject.id.toString(),
        name: this.state.tag,
        value: this.state.selectedText,
        metadata: {
          tagLine: this.state.tagLine,
        },
      })
      .then((res) => {
        if (res.data) {
          // spread.getActiveSheet().addCustomName(`${this.state.tag}`,`=${this.state.selectedText}`, 0, 0);
          this.props.addTagToFile(res.data);
          this.props.recalcFormulasAfterTagAddition();
          this.props.saveSpread(spread);

          this.setState({
            tag: "",
            selectedText: "",
            tagLine: "",
            showTagPopover: false,
            nameTaken: false,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          this.setState({ nameTaken: true });
        }
      });

    // this.props.addTagToFile(this.state.tag, this.state.selectedText, this.state.tagLine)
  };

  cancelNewTag = () => {
    window.getSelection().empty();
    this.setState({
      showTagPopover: false,
      selectedText: "",
      tag: "",
    });
  };

  toggleTagPopover = () => {
    this.setState({
      showTagPopover: !this.state.showTagPopover,
    });
  };

  handleTagInputChange = (e) => {
    this.setState({
      tag: e.target.value,
      nameTaken: false,
    });
  };

  textRenderer = (textItem, line, pattern) => {
    return this.highlightPattern(textItem.str, line, pattern);
  };

  highlightPattern = (text, line, pattern) => {
    const splitText = text.split(pattern);

    if (splitText.length <= 1) {
      return text;
    }

    const matches = text.match(pattern);

    var index = line.indexOf(pattern);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index] && matches.input.trim() == line.trim()
          ? [
              ...arr,
              element,
              <span
                key={index}
                style={{ backgroundColor: "#A1E1FF", color: "black" }}
              >
                {matches[index]}
              </span>,
            ]
          : [...arr, element],
      []
    );
  };

  render() {
    const { numPages, pageNumber, jsonData, xmlData, showTagTooltip } =
      this.state;

    return (
      <>
        <div
          className="right-panel-times-icon-parent"
          onClick={this.props.closeViewer}
        >
          <FontAwesomeIcon icon={faTimes} className="right-panel-times-icon" />
        </div>
        <div
          onClick={
            this.props.fileType == "pdf" || "docx" || "doc"
              ? this.getSelectedText
              : undefined
          }
          id="TooltipExample"
        >
          {/* {console.log({props: this.props})} */}
          {this.props.fileType === "pdf" && (
            <div className="zoom_main_wrapper">
              <div className="zoom_container">
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  style={{
                    color: this.props.fileViewerZoom > 0.5 ? `#0090d1` : `gray`,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.props.fileViewerZoom > 0.5 &&
                    this.props.updateFileViewerZoom(
                      this.props.fileViewerZoom - 0.25
                    )
                  }
                />
                <Input
                  style={{
                    width: "80px",
                    height: "40px",
                    background: "#f2f5f8",
                    textAlign: "center",
                    border: "none",
                    outline: "none",
                  }}
                  readonly
                  value={this.props.fileViewerZoom}
                />
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{
                    color:
                      this.props.fileViewerZoom < 1.75 ? `#0090d1` : `gray`,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.props.fileViewerZoom < 1.75 &&
                    this.props.updateFileViewerZoom(
                      this.props.fileViewerZoom + 0.25
                    )
                  }
                />
              </div>
            </div>
          )}

          {this.props.fileType === "pdf" && (
            <>
              <div>
                {/* {this.state.isFileOpened && this.props.fileType === "pdf" && ( */}
                <div className="zoom_main_wrapper">
                  {/* <div className="zoom_container">
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        style={{
                          color:
                            this.props.fileViewerZoom > 0.5 ? `#0090d1` : `gray`,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.props.fileViewerZoom > 0.5 &&
                          this.props.updateFileViewerZoom(
                            this.props.fileViewerZoom - 0.25
                          )
                        }
                      />
                      <Input
                        style={{
                          width: "80px",
                          height: "40px",
                          background: "#f2f5f8",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                        }}
                        readonly
                        value={this.props.fileViewerZoom}
                      />
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{
                          color:
                            this.props.fileViewerZoom < 1.75 ? `#0090d1` : `gray`,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.props.fileViewerZoom < 1.75 &&
                          this.props.updateFileViewerZoom(
                            this.props.fileViewerZoom + 0.25
                          )
                        }
                      />
                    </div> */}
                </div>
              </div>

              <Document
                file={this.props.file}
                onLoadSuccess={({ numPages }) => this.setState({ numPages })}
              >
                {Array.from(new Array(this.state.numPages), (el, index) => (
                  <>
                    <Page
                      pageNumber={index + 1}
                      onLoadSuccess={this.removeTextLayerOffset}
                      customTextRenderer={
                        this.props.highlightedTag !== ""
                          ? (text) =>
                              this.textRenderer(
                                text,
                                this.props.highlightedTag.metadata.tagLine,
                                this.props.highlightedTag.value
                              )
                          : undefined
                      }
                      scale={this.props.fileViewerZoom}
                    />
                  </>

                  /*<Page
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                />*/
                ))}
              </Document>

              {/*<p>Page {pageNumber} of {numPages}</p>
                                    {pageNumber > 1 && <button onClick={this.showPreviousPage}>Previous</button>}
                                    {pageNumber < numPages && <button onClick={this.showNextPage}>Next</button>}*/}
            </>
          )}

          {this.props.fileType === "json" && (
            <pre>
              {this.readJson(this.props.file)}
              {jsonData && jsonData}
            </pre>
          )}

          {this.props.fileType === "xml" && (
            <pre>
              {this.readXml(this.props.file)}
              {xmlData && xmlData}
            </pre>
          )}
          
          {this.props.fileType === "xls" ||
            this.props.fileType === "xlsx" && (
              this.props.file && <ExcelViewer file={this.props.file}/>
            )}
          {!["pdf","json","xml","xlsx","xls"].includes(this.props.fileType) &&(
              <FileViewer
                fileType={this.props.fileType}
                filePath={URL.createObjectURL(this.props.file)}
                onError={this.onError}
              />
            )}
        </div>

        <Modal
          isOpen={this.state.showTagPopover}
          toggle={this.toggleTagPopover}
          autoFocus={false}
        >
          <ModalHeader toggle={this.cancelNewTag}>Etichetta</ModalHeader>
          <ModalBody>
            Inserisci etichetta per l'elemento selezionato:{" "}
            {this.state.selectedText}
            <div style={{ height: "15px" }}></div>
            <Input
              type="email"
              name="email"
              value={this.state.tag}
              onChange={this.handleTagInputChange}
              placeholder="Put tag name here"
              autoFocus={true}
            />
            {this.state.nameTaken && (
              <p>
                Tag name is already assigned to a value in this spreadsheet.
                Please enter another name.
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.confirmNewTag}>
              Aggiungi
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    highlightedTag: state.spreadsheet.highlightedTag,
    fileType: state.spreadsheet.activeFileRightPanel.fileObject.filename
      .split(".")
      .pop(),
    tags: state.spreadsheet.tags,
    spreadsheet: state.spreadsheet,
  };
}

export default connect(mapStateToProps, { addTagToFile, closeViewer })(
  ModalComponent
);
