import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from 'reactstrap'
import { faTimes, faEllipsisH, faSearch, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { Popover, InputGroup, PopoverBody, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-regular-svg-icons"
import { closeRightPanel, openViewer, deleteTagFromFile, markTagAsHighlighted } from "../../actions";
import mapLettersToNumbersConfig from "../../mapLettersToNumbersConfig";
import axios from "../../axios";
import '../../styles/RightPanel.css'

const RightPanel = (props) => {

  const { t } = useTranslation();

  const { activeFileRightPanel: activeFile, tags } = props.spreadsheet;

    const [state, setState] = useState({
        showTagsEditModePopover: false,
        tagsEditMode: false,
        tagsToBeDeleted : [],
        searchTagNameInput: '',
        tagsToBeShown: tags,
        editDescription: false,
        description: '',
        isFileOpened: false
    })
    useEffect(() => {
        if(activeFile) {
            axios.get(`/api/files/${activeFile.fileObject.id}`)
                .then(res => res.data.description && setState(state => ({...state, description: res.data.description})))
        }
    }, [activeFile])

    useEffect(() => {
        setState(state => ({
            ...state,
            tagsToBeShown: tags
        }))
    }, [tags])

    const toggleTagsEditModePopover = () => {
        setState(state => ({
            ...state,
            showTagsEditModePopover: !state.showTagsEditModePopover
        }))
    }

    const addTagToDeletedList = tag_id => {
        setState(state => ({
            ...state,
            tagsToBeDeleted: [...state.tagsToBeDeleted, tag_id]
        }))
    }

    const emptyTagsDeletedList = () => {
        setState(state => ({
            ...state,
            tagsToBeDeleted: [],
            tagsEditMode: false
        }))
    }

    const sendTagsListForDeletion = async () => {
        if(state.tagsToBeDeleted.length > 0){
            await state.tagsToBeDeleted.forEach(tagId => {
                axios.delete(`/api/tags/${tagId}`)
                    .then((res, index) => {
                        props.deleteTagFromFile(tagId);
                        props.recalcFormulasAfterTagDeletion();
                    })
            })
            emptyTagsDeletedList()
        }
        props.recalcFormulasAfterTagDeletion();
    }
    const handleTagNameSearch = (e) => {
        setState(state => ({
            ...state,
            searchTagNameInput: e.target.value
        }))
    }

    const filterTagNames = () => {
        state.searchTagNameInput === ''
            ? setState(state => ({ ...state, tagsToBeShown: tags }))
            : setState(state => ({ ...state, tagsToBeShown: tags.filter(tag => tag.name.includes(state.searchTagNameInput)) }))
    }

    const markTagAsHighlighted = (tag) => {
        props.markTagAsHighlighted(tag)
    }

    const saveDescription = () => {
        axios.patch(`/api/files/${activeFile.fileObject.id}`, {
            filename: activeFile.fileObject.filename,
            description: state.description
        }).then(res => console.log(res))
        setState(state => ({ ...state, editDescription: false }))
    }

    const handleDescriptionChange = (e) => setState(state => ({ ...state, description: e.target.value }))

    const enableTagsEditMode = () => setState(state => ({ ...state, tagsEditMode: !state.tagsEditMode, showTagsEditModePopover: false }))
    const disableTagsEditMode = () => setState(state => ({ ...state, tagsEditMode: false }))

    const enableDescriptionEditMode = () => setState(state => ({ ...state, editDescription: true }))

    return(
        <div>

            <Row>

                <div className="right-panel-times-icon-parent" onClick={() => {
                    //props.updateFileViewerZoom(1);
                    props.closeRightPanel();
                }}>
                    <FontAwesomeIcon icon={faTimes} className="right-panel-times-icon" />
                </div>
                <Col sm="2">
                    <Row className="right-panel-first-row-title">{t("cell")}</Row>
                    <div style={{height: '10px'}}></div>
                    <Row className="right-panel-first-row-content">{activeFile && mapLettersToNumbersConfig[activeFile.column]}{activeFile && activeFile.row + 1}</Row>
                </Col>
                <Col sm="4">
                    <Row className="right-panel-first-row-title">{t("file type")}</Row>
                    <div style={{height: '10px'}}></div>
                    <Row className="right-panel-first-row-content">{activeFile && activeFile.fileObject.filename.split('.').pop().toUpperCase()}</Row>
                </Col>
                {/*<Col sm="6" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} onClick={props.closeRightPanel}>
                    <div className="right-panel-times-icon-parent">
                        <FontAwesomeIcon icon={faTimes} className="right-panel-times-icon" />
                    </div>
                </Col>*/}
            </Row>

            <hr className="right-panel-hr" />

            <div className="right-panel-description-parent">
                <p className="right-panel-title">File appena aggiunto qui</p>
                {state.editDescription ? (
                    <span onClick={saveDescription}><FontAwesomeIcon icon={faSave} />Save</span>
                ) : (
                    <span onClick={enableDescriptionEditMode}><FontAwesomeIcon icon={faEdit} />Edit</span>
                )}

            </div>
            <Input
                type="textarea"
                value={state.description}
                onChange={handleDescriptionChange}
                name="text"
                style={{ border: "none", backgroundColor: 'white' }}
                disabled={!state.editDescription}
                placeholder="Aggiungi una descrizione a questo file"
            />


           {/* <p className="right-panel-description">
                Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. Proin eleifend eget quam ut efficitur. Mauris a accumsan mauris. Phasellus egestas et risus sit amet hendrerit. Nulla facilisi. Cras urna sem, vulputate sed condimentum a, posuere vel enim.
            </p>*/}

            <div style={{height: '45px'}}></div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="right-panel-view-file" onClick={() => {
                    props.openViewer();
                    setState(state => ({...state, isFileOpened: true}))
                }}>{t("view file")}</button>
            </div>

            <hr className="right-panel-hr" />

            <div className="tags-label-parent">
                <span>{t("tags")}</span>
                <FontAwesomeIcon icon={faEllipsisH} style={{ color: 'rgb(0, 80, 117)' }} id="toggle-tags-edit-mode" />
            </div>

            <div style={{height: '10px'}}></div>

            <InputGroup>
                <Input placeholder={t("search tag")} value={state.searchTagNameInput} onChange={handleTagNameSearch} />
                <InputGroupAddon addonType="append">
                    <InputGroupText style={{ background: 'linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%)' }}>
                            <FontAwesomeIcon icon={faSearch} style={{ color: 'rgb(242, 245, 248)' }} onClick={filterTagNames} />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>

            <div style={{height: '10px'}}></div>
            <div>
                {state.tagsToBeShown.map(tag => (
                        <button
                            className={`vapor-button vapor-button_chip ${state.tagsToBeDeleted.some(actTag => actTag === tag._id) && 'disabled-chip'}`}
                            onClick={() => markTagAsHighlighted(tag)}
                            key={tag._id}
                        >
                            {state.tagsEditMode && <FontAwesomeIcon icon={faTimes} className="times-icon-remove-tag" onClick={(e) => {
                                e.stopPropagation();
                                addTagToDeletedList(tag._id)
                            }} />}
                            {tag.name} | {tag.value}
                        </button>
                ))}
            </div>

            {state.tagsEditMode && (
                <>
                    <div style={{height: '30px'}}></div>
                    <div className="tags-edit-mode-footer">
                        <span className="tags-edit-mode-cancel" onClick={emptyTagsDeletedList}>{t("cancel")}</span>
                        <span style={{width: '20px'}}></span>
                        <button className="tags-edit-mode-save-changes" onClick={sendTagsListForDeletion}>{t("save")}</button>
                    </div>
                </>
            )}

            <Popover placement="top" isOpen={state.showTagsEditModePopover} target="toggle-tags-edit-mode" toggle={toggleTagsEditModePopover}>
                <PopoverBody>
                    <span className="edit-tags-label" onClick={enableTagsEditMode}>{t("edit tag")}</span>
                </PopoverBody>
            </Popover>

        </div>
    )
}

function mapStateToProps(state){
    return {
        spreadsheet: state.spreadsheet,
    }
    // return{
    //     activeFile: state.spreadsheet.activeFileRightPanel,
    //     fileType: state.spreadsheet.activeFileRightPanel.fileObject.filename.split('.').pop(),
    //     tags: state.spreadsheet.tags
    // }
}

export default connect(mapStateToProps, { closeRightPanel, openViewer, deleteTagFromFile, markTagAsHighlighted })(RightPanel)
