import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import { fetchAllProjects } from "../../actions";
import "../../styles/Login.css";

const ChangePass = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  const [state, setState] = useState({
    username: "",
    password: "",
    usernameError: false,
    passwordError: false,
  });

  const onEmailChange = (e) =>
    setState((state) => ({
      ...state,
      username: e.target.value,
      usernameError: false,
    }));
  const onPasswordChange = (e) =>
    setState((state) => ({
      ...state,
      password: e.target.value,
      passwordError: false,
    }));

  const login = () => {
    if (state.username === "" && state.password === "") {
      setState((state) => ({
        ...state,
        usernameError: true,
        passwordError: true,
      }));
    } else if (state.username === "") {
      setState((state) => ({ ...state, usernameError: true }));
    } else if (state.password === "") {
      setState((state) => ({ ...state, passwordError: true }));
    } else {
      axios
        .post("/api/auth/login", {
          username: state.username,
          password: state.password,
        })
        .then(async (res) => {
          localStorage.setItem("access", res.data.accessToken);
          localStorage.setItem("refresh", res.data.refreshToken);
          await dispatch(fetchAllProjects());
          setState((state) => ({ ...state, loading: false }));
          history.push("/");
        })
        .catch(() => {
          // setState(state => ({...state, invalidPassword: true, invalidUsername: true, loading: false }));
        });
    }
  };

  return (
    <div>
      {/*<div className="login-top-Nav">*/}
      {/*    <div>*/}
      {/*        <p className="login_logo">*/}
      {/*            <span className="data-text">Data</span>*/}
      {/*            <span className="manager-text">Manager</span>*/}
      {/*        </p>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <div className="login_page_container">
        <div className="login_container">
          <div className="teamsystem_id_container">
            <h2 className="login_title">Modifica la tua password</h2>
            <p className="teamsystem-login_subtitle" style={{height: "fit-content"}}>
              Password scaduta. Inserisci una nuova password.
            </p>
          </div>
          <div className="login_form_container">
            <p className="input_label" >
              Email
            </p>
            <input
              className="login_input"
              style={{ border: state.usernameError && "1px solid red" }}
              type="text"
              value={state.username}
              onChange={onEmailChange}
              placeholder="nomeutente@teamsystem.com"
            />

            <p className="input_label" style={{ marginTop: "1rem" }}>
              Password Attuale
            </p>
            <input
              className="login_input"
              style={{ border: state.usernameError && "1px solid red" }}
              type="password"
              value={state.username}
              onChange={onEmailChange}
              placeholder="Inserisci password"
            />

            <p className="input_label" style={{ marginTop: "1rem" }}>
              Nuova Password
            </p>
            <input
              className="login_input"
              type="password"
              style={{ border: state.passwordError && "1px solid red" }}
              value={state.password}
              onChange={onPasswordChange}
              placeholder="Inserisci password"
            />

            <p className="input_label" style={{ marginTop: "1rem" }}>
              Conferma Password
            </p>
            <input
              className="login_input"
              type="password"
              style={{ border: state.passwordError && "1px solid red" }}
              value={state.password}
              onChange={onPasswordChange}
              placeholder="Inserisci nuovamente la password"
            />

            <div className="register_buttons_row">
              <button className="redirect_to_signup_Button">Annulla</button>
              <button
                className="access_team_button"
                onClick={() => {
                  history.push("/register-drag");
                }}
              >
                Conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
