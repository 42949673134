import React from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import { renameProject } from "../../actions";
import axios from "../../axios";
import { RENAME_PROJECT } from "../../actions/types";
import {NotifySuccess, NotifyError} from '../Notifications';
import '../../styles/Notification.css';

const RenameProjectModal = ({
  isOpen = false,
  onClose,
  projectId,
  projectName,
  getProjects,
}) => {
  const [state, setState] = React.useState({
    loading: false,
    name: projectName,
    error: false,
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const setName = (name) => {
    setState((state) => ({ ...state, name }));
  };

  const handleRenameProject = () => {
    setState((state) => ({ ...state, loading: true }));

    axios
      .patch(`/api/projects/${projectId}`, {
        name: state.name,
      })
      .then((res) => {
        // dispatch(renameProject(projectId, state.name))
        getProjects();
        setState((state) => ({ ...state, loading: false }));
        onClose();
        // successNotify("Your spreadsheet was renamed successfully!");
        NotifySuccess("Your spreadsheet was renamed successfully!")

      })
      .catch((error) =>{
        setState((state) => ({ ...state, loading: false, error: true }));
        NotifyError("Your spreadsheet couldn't be renamed!")
        }
      );
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={onClose}>
        <span className="modal-title">{t("rename")}</span>
      </ModalHeader>
      <ModalBody>
        <p className="name">
          <strong>Rename this project</strong>
        </p>
        <div style={{ height: "8px" }}></div>

        <input
          type="text"
          placeholder="input placeholder"
          className="spreadsheet-name"
          value={state.name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </ModalBody>
      <ModalFooter>
        <button className="discard-button" onClick={onClose}>
          {t("discard")}
        </button>
        <button className="create-button" onClick={handleRenameProject}>
          {state.loading && (
            <Spinner size="sm" color="white" style={{ marginRight: "5px" }} />
          )}
          {t("save")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RenameProjectModal;
