const it = {
    translations: {
        "Data Manager": "Document Tag ti aiuta a organizzare ed elaborare le informazioni dai tuoi file in un istante. Organizza subito i tuoi dati.",
        "All Projects": "Fogli di calcolo",
        "spreadsheet in folders": "fogli di calcolo sono stati modificati la settimana scorsa",
        "Recent": "Fogli di calcolo recenti",
        "Favourites": "Preferiti",
        "Archived": "Archivio",
        "Deleted": "Eliminati",
        "All spreadsheets": "Fogli di calcolo",
        "all in folder": "fogli di calcolo in questa cartella",
        "search": "Cerca",
        "open": "Apri",
        "create": "Creare",
        "create new": "Creare nuovo",
        "create new spreadsheet": "Crea un nuovo foglio di calcolo",
        "spreadsheet name": "Inserisci un nome",
        "name your spreadsheet": "Titolo foglio di calcolo (opzionale)",
        "name your spreadsheet info": "È possibile rinominare il foglio di calcolo in qualsiasi momento.",
        "added favorites": "Aggiungi ai preferiti",
        "add favorites": "Aggiungi ai preferiti",
        "rename": "Rinomina",
        "archive": "Archivia",
        "download": "Scarica file collegati",
        "delete": "Elimina",
        "spread size": "Dimensione:",
        "today": "Oggi",
        "yesterday": "Ieri",
        "last week": "La settimana scorsa",
        "last opened": "Ultima apertura",
        "last updated": "Ultima aggiornamento",
        "seconds ago": " secondi fa",
        "minutes ago": " minuti fa",
        "hours ago":" ore fa",
        "days ago": " giorni fa",

        "change data": "MODIFICA DATI UTENTE", 
        "manage data text": "Gestisci i dati legati al tuo profilo utente",
        "discard": "Scartare",
        "save": "Salvare le modifiche",
        "delete project": "Elimina foglio di calcolo",
        "edit profile": "Modifica Profilo",
        "fullname": "Nome Cognome",
        "name": "Nome",
        "surname": "Cognome",
        "company": "Azienda (optional)",
        "company name": "Azienda Nome",
        "username": "Nome utente (non può essere modificato)",
        "change password": "Cambia password",
        "current pass": "Password attuale ",
        "required": "(Necessaria)",
        "current pass info": "Digita la tua password attuale per poterla cambiare con una nuova.",
        "new pass": "Nuova password",
        "new pass info": "Ti disconnetteremo da altri browser per tenerti al sicuro.",
        "confirm new pass": "Conferma la nuova password",
        "confirm pass": "Conferma password",
        "edit": "Modifica",
        "exit": "Esci",
        "cancel": "Anulla",
        "delete button": "Elimina",
        "my profile": "Il Mio Profilo",

        "no data": "Nessun foglio di calcolo presente",
        "start manage data": "Puoi trascinare e rilasciare i file dalla tua libreria locale e aggiungerli direttamente al tuo foglio di calcolo.",
        "suported files": "File supportati: ",

        "cell": "Cella",
        "file type": "Tipo di file",
        "view file": "Visualizza file",
        "edit tag": "Modifica le etichette",
        "tags": "Etichetta ",
        "search tag": "Cerca",

    }
}

export default it;