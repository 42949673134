import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import EditProfileInfo from "../components/ProfileSettings/EditProfileInfo";
import ChangePassword from "../components/ProfileSettings/ChangePassword";
import "../styles/ProfileSettings.css";
import { NotifyError, NotifyInfo, NotifySuccess, NotifyWarning } from "../components/Notifications";
import axios from "../axios";

const ProfileSettings = ({ isOpen, handleCancel, profileData }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("edit-profile");

  const [state, setState] = useState({
    data: {},
    url: "",
    disableButton: true
  })

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const setDataChanges = (dataToChage, urlForChanges) => {
    setState(state => ({...state, data: dataToChage, url: urlForChanges}))
  }

  const saveChanges = () => {
    axios.post(state.url, state.data)
    .then(() => {
      handleCancel();
      profileData();
      NotifySuccess("Changes were updated successfully!")
      
    })
    .catch(err => {
      NotifyError("Problem with saving changes")
    })
  }

  const enableButton = () => {
    setState(state => ({...state, disableButton: false}))
  }

  return (

    <div>
    <Modal className="profile_settings_modal" isOpen={isOpen}>
      <div className="modal_titles_wrapper">
        <h3 className="settings_modal_title">{t("change data")}</h3>
        <p className="settings_modal_subtitle">{t("manage data text")}</p>
      </div>
      <div>
        <Nav tabs>
          {/* <NavItem className="edit-profile-tabs-parent"> */}
          <NavItem className={activeTab === "edit-profile" ? 'edit-profile-tabs-parent_active': 'edit-profile-tabs-parent'} >
            <NavLink
              onClick={() => {
                toggle("edit-profile");
              }}
            >
              <span className="edit-profile-tabs">{t("edit profile")}</span>
            </NavLink>
          </NavItem>
          {/* <NavItem className="edit-profile-tabs-parent"> */}
          <NavItem className={activeTab === "change-password" ? 'edit-profile-tabs-parent_active': 'edit-profile-tabs-parent'} >
            <NavLink
              onClick={() => {
                toggle("change-password");
              }}
            >
              <span className="edit-profile-tabs">{t("change password")}</span>
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <ModalHeader toggle={handleCancel} style={{borderBottom: "none", padding: "8px 16px"}}>
        <span className="modal-title">
          {activeTab === "edit-profile"
            ? t("edit profile")
            : activeTab === "change-password"
            ? t("change password")
            : activeTab === "email-addresses" && "Email Addresses"}
        </span>
      </ModalHeader>
      <ModalBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="edit-profile">
            <EditProfileInfo dataToChange={setDataChanges} enableButton={enableButton}/>
          </TabPane>
          <TabPane tabId="change-password">
            <ChangePassword dataToChange={setDataChanges} enableButton={enableButton}/>
          </TabPane>
          {/* <TabPane tabId="email-addresses">
                        <EmailAddresses />
                    </TabPane> */}
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <button className="discard-button" onClick={handleCancel}>
          {t("discard")}
        </button>
        <button className="create-button" onClick={saveChanges} disabled={state.disableButton ? true : false}>
          {t("save")}
        </button>
      </ModalFooter>
    </Modal>
    </div>
  );
};

export default ProfileSettings;
