import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
} from "reactstrap";
import { useTranslation } from "react-i18next"

import "../../styles/ProfileSettings.css";

const ChangePassword = ({dataToChange, enableButton}) => {
    const { t } = useTranslation();

    const [state, setState] = React.useState({
      currentPassword: "",
      newPassword: "",
      newPassConfirm: ""
    })

    React.useEffect(() => {
      let passData = {
        newPassword: state.newPassword,
        newPasswordConfirmation: state.newPassConfirm,
        currentPassword: state.currentPassword
      }
      dataToChange(passData, "/api/auth/change-password")
    }, [state.newPassword, state.newPassConfirm, state.currentPassword])

    const changeCurrentPass = (e) => {
      e.persist();
      setState(state => ({ ...state, currentPassword: e.target.value }))
      enableButton()
    }

    const changeNewPass = (e) => {
      e.persist();
      setState(state => ({ ...state, newPassword: e.target.value }))
      enableButton()
    }

    const changeNewPassConfirm = (e) => {
      e.persist();
      setState(state => ({ ...state, newPassConfirm: e.target.value }))
      enableButton()
    }

  return (
    <div>
      <div>
        <Label className="label">
          <p>{t("current pass") + t("required")}</p>
          <p>{t("current pass info")}</p>
        </Label>
        <Input
          className="password_input"
          type="password"
          placeholder={t("current pass")}
          value={state.currentPassword}
          onChange={changeCurrentPass}
        />
      </div>

      <div style={{ margin: "25px 0" }}>
        <Label className="label">
          <p>{t("new pass")} *</p>
          <p>{t("new pass info")}</p>
        </Label>
        <Input
          className="password_input"
          type="password"
          placeholder={t("new pass")}
          value={state.newPassword}
          onChange={changeNewPass}
        />
      </div>

      <div style={{ marginBottom: "40px" }}>
        <Label className="label">
          <p>{t("confirm new pass") + t("required")}</p>
          {/* <p>Type your current password to be able to change to a new one.</p> */}
        </Label>
        <Input
          className="password_input"
          type="password"
          placeholder={t("confirm pass")}
          value={state.newPassConfirm}
          onChange={changeNewPassConfirm}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
