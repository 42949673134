import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faEllipsisH, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { PopoverBody, Popover, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import fileSize from "filesize";
// import { toggleFromFavourite, renameProject } from "../../actions";
import axios from "../../axios";
import RenameProjectModal from "./RenameProjectModal";
import ArchiveProjectModal from "./ArchiveProjectModal";
import DeleteProjectModal from "./DeleteProjectModal";
import { NotifyError, NotifySuccess, NotifyInfo, NotifyWarning } from '../Notifications'
// import "../../styles/DashboardNoProjects.css";
import "../../styles/DashboardAllProjects.css";
import "../../styles/Popover.css";

export const ProjectItem = ({
  project,
  getProjects,
  reloadDashboard=f=>f
}) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  let history = useHistory();

  React.useEffect(() => {
    findTimeDifference()
  }, [project, i18n.language])

  const [state, setState] = React.useState(
    {
      renameModal: false,
      archiveModal: false,
      deleteModal: false,
      showPopover: false,
      favoriteProjects: [],
      isFavorite: project.isFavorite,
      timeDifference: ''
    },
    project.id
  );

  const notify = () => toast("wow so easy!");

  const findTimeDifference = () => {
    const today = new Date();
    const projectDate = new Date(project.accessedAt)
    const diffTime = Math.abs(today - projectDate)

    var seconds = parseInt(diffTime / 1000)
    var minutes = parseInt( seconds / 60)
    var hours = parseInt( minutes / 60 )
    var days = parseInt( hours / 24);

    if(seconds <=  60) {
      setState(state => ({...state, timeDifference: t("last updated") + " " + seconds + t("seconds ago")}))
    }
    else if(seconds >  60 && minutes <= 60 ) {
      setState(state => ({...state, timeDifference: t("last updated") + " " + minutes + t("minutes ago")}))
    }
    else if(minutes > 60 && hours <= 24) {
      setState(state => ({...state, timeDifference: t("last updated") + " " + hours + t("hours ago")}))
    }
    else if( hours > 24 ) {
      setState(state => ({...state, timeDifference: t("last updated") + " " + days + t("days ago")}))
    }
  }

  const toggleProjectFromFavourite = () => {
    // dispatch(toggleFromFavourite(project_id, newValue));
    axios.patch(`/api/projects/${project.id}`, {
      "isFavorite": !project.isFavorite
  }).then(() => {
        // setState(state => ({...state, favoriteProjects: res.data,isFavorite: newValue}))
        getProjects()
        reloadDashboard()
        !project.isFavorite ? NotifyInfo("Spreadsheet was added to Favourites") : NotifyInfo("Spreadsheet was removed from Favourites")
        
      })
      .catch(error => {
        NotifyError("oh no! Something happened!")
      })
  };

  const setRenameModal = (renameModal) => {
    setState((state) => ({ ...state, renameModal }));
  };

  const setArchiveModal = (archiveModal) => {
    setState((state) => ({ ...state, archiveModal }));
  };

  const setDeleteModal = (deleteModal) => {
    setState((state) => ({ ...state, deleteModal }));
  };

  const setShowPopover = (showPopover) => {
    setState((state) => ({ ...state, showPopover }));
  };

  const onPopoverRenameClick = () => {
    setShowPopover(false);
    setRenameModal(true);
  };

  const onPopoverArchiveClick = () => {
    setShowPopover(false);
    setArchiveModal(true);
  };

  const onPopoverDeleteClick = () => {
    setShowPopover(false);
    setDeleteModal(true);
  };

  const setFavorites = () => {
    toggleProjectFromFavourite();
    setShowPopover(false);
  }

  const archiveProject = () => {
    axios.patch(`/api/projects/${project.id}`, {
      "isArchived": !project.isArchived
  })
      .then(res => {
          setState(state => ({...state, loading: false }))
          setShowPopover(!state.showPopover)
          getProjects()
          reloadDashboard()
          !project.isArchived ? NotifyInfo("Spreadsheet is archived!") : NotifyInfo("Spreadsheet is de-archived!")
      })
      .catch(error => {
        setState((state) => ({ ...state, loading: false, error: true }))
        NotifyError("Spreadsheet couldnt be archived!")
    })
  }

  return (
    <>
      <div className="project__wrapper">
        <div className="project_left-descriptions" onClick={() => history.push(`/project/${project.id}`)}>
          <svg
            className="project-fileIcon"
            style={{ width: "22px", height: "24px" }}
            fill="rgb(0, 195, 234)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" />
          </svg>
          <h4>{project.name}</h4>
          <p></p>
          <p className="last_updated">
            {state.timeDifference}{" "}
            <br />
            <span>{project.totalFiles} files / {fileSize(project.totalFilesSize)}</span>
          </p>
        </div>
        <div className="project_right-buttons">
          <button type="button" id={`ID-${project.id}`}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>

          <ItemPopover
            onPopoverRenameClick={onPopoverRenameClick}
            onPopoverArchiveClick={archiveProject}
            onPopoverDeleteClick={onPopoverDeleteClick}
            setFavorites={setFavorites}
            // toggleProjectFromFavourite={toggleProjectFromFavourite}
            target={`ID-${project.id}`}
            isOpen={state.showPopover}
            toggle={() => setShowPopover(!state.showPopover)}
            project={project}
          />
          <button onClick={() => {
            toggleProjectFromFavourite()
          }}>
            {project.isFavorite ?
              <svg
              fill="#0090D1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
            </svg>
            :
            <svg
              fill="#0090D1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" />
            </svg>
            }
            
          </button>
          <button>
            <Link to={`/project/${project.id}`}><FontAwesomeIcon icon={faArrowRight} /></Link>
          </button>
        </div>
      </div>

      {state.renameModal && (
        <RenameProjectModal
          isOpen={state.renameModal}
          onClose={() => setRenameModal(false)}
          projectName={project.name}
          projectId={project.id}
          getProjects={getProjects}
        />
      )}

      {state.archiveModal && (
        // <ArchiveProjectModal
        //   isOpen={state.archiveModal}
        //   onClose={() => setArchiveModal(false)}
        //   projectId={project.id}
        //   project={project}
        //   getprojects={getProjects}
        //   reloadDashboard={reloadDashboard}
        // />
        archiveProject()
      )}

      {state.deleteModal && (
        <DeleteProjectModal
          isOpen={state.deleteModal}
          onClose={() => {
            setDeleteModal(false);
          }}
          projectId={project.id}
          project={project}
          getProjects={getProjects}
          reloadDashboard={reloadDashboard}
        />
      )}
    </>
  );
};

const ItemPopover = ({
  isOpen,
  target,
  toggle,
  project,
  onPopoverRenameClick = (f) => f,
  onPopoverArchiveClick = (f) => f,
  onPopoverDeleteClick = (f) => f,
  setFavorites = f=> f
  // toggleProjectFromFavourite = (f) => f
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();

  const [modal, setModal] = React.useState(false)
  const activeLeftNavbarMenu = useSelector(
      (state) => state.dashboard.activeLeftNavbarMenu
  );

  const toggleDeleteModale = () => setModal(!modal)

  return (
    <>
      <Popover isOpen={isOpen} toggle={toggle} placement="bottom" target={target} trigger="legacy">
        <PopoverBody className="popover_options">
          <div className="open_button" onClick={() => history.push(`/project/${project.id}`)}>{t("open")}</div>
          <div className="add_favorite" onClick={setFavorites}>{ project.isFavorite ? "Remove from favorites" : t("add favorites")}</div>
          <div className="rename_button" onClick={onPopoverRenameClick}>
            {t("rename")}
          </div>
          <div className="archive_button" onClick={onPopoverArchiveClick} >
            {/* {activeLeftNavbarMenu === 'Archived' ? 'De-archive' : 'Archive'} */}
            {project.isArchived ? "De-archive" : "Archive"}
          </div>
          <div className="download_button">{t("download")}</div>
          <div className="delete_spreadsheet" 
          // onClick={onPopoverDeleteClick}
          onClick={() => {
            project.isDeleted ? onPopoverDeleteClick() : 
              toggle()
              toggleDeleteModale()
          }}
          >
            {/* {activeLeftNavbarMenu === 'Deleted' ? 'Restore' : 'Delete'} */}
            {project.isDeleted ? "Restore" : t("delete")}
          </div>
          <div className="mb_info">{t("spread size")} 15.56 MB</div>
        </PopoverBody>
      </Popover>
      <Modal className="confirm_delete_modal" isOpen={modal} toggle={toggleDeleteModale}>
        <ModalHeader>
          <h3 className="delete_modal_title">Elimina foglio di calcolo</h3>
        </ModalHeader>
        <ModalBody>
          <p className="delete_modal_text">"Attenzione! Continuando eliminerai definitvamente il foglio di calcolo."</p>
        </ModalBody>
        <ModalFooter>
          <button className="modal_cancel" onClick={toggleDeleteModale}>{t("cancel")}</button>
          <button className="modal_delete" onClick={onPopoverDeleteClick}>{t("delete button")}</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ItemPopover;
