import {
    UPDATE_ACTIVE_LEFT_NAVBAR_OPTION,
    CHANGE_APP_LANGUAGE,
    FETCH_ALL_PROJECTS_STARTED,
    FETCH_ALL_PROJECTS_FAILURE,
    FETCH_ALL_PROJECTS_SUCCESS,
    REMOVE_PROJECT_FROM_FAVOURITE,
    ADD_NEW_PROJECT,
    TOGGLE_PROJECT_FROM_FAVOURITE,
    RENAME_PROJECT,
    TOGGLE_DELETE_PROJECT,
    TOGGLE_ARCHIVE_PROJECT,
    SET_FILE_FOR_RIGHT_PANEL,
    SETUP_ACTUAL_PROJECT,
    CLOSE_RIGHT_PANEL,
    OPEN_VIEWER,
    ADD_TAG_TO_FILE,
    DELETE_TAG_FROM_FILE,
    CLOSE_VIEWER,
    ADD_FILE_TO_PROJECT,
    MARK_TAG_AS_HIGHLIGHTED
} from "./types";
import axios from "../axios";

export const updateActiveLeftNavbarOption = (newActiveMenu) => {
    return{
        type: UPDATE_ACTIVE_LEFT_NAVBAR_OPTION,
        payload: newActiveMenu
    }
}

export const fetchAllProjects = () => async (dispatch) => {

    dispatch({ type: FETCH_ALL_PROJECTS_STARTED })

    await axios.get('/api/projects')
        .then(res => {
            dispatch({ type: FETCH_ALL_PROJECTS_SUCCESS, payload: res.data })
        })
        .catch(err => dispatch({ type: FETCH_ALL_PROJECTS_FAILURE, payload: err.request.response.message }))

}

export const removeFromFavourite = (project_id) => (dispatch) => {

    axios.patch(`/api/projects/${project_id}`, {
        "isFavorite": false
    })
        .then(res => dispatch({ type: REMOVE_PROJECT_FROM_FAVOURITE, payload: project_id}))
        .catch(error => console.log(error))

}

export const renameProject = (project_id, project_new_name) => (dispatch) => {

    const data = {
        project_id,
        project_new_name
    }

    dispatch({ type: RENAME_PROJECT, payload: data})

}

export const toggleArchiveProject = project_id => {
    return{
        type: TOGGLE_ARCHIVE_PROJECT,
        payload: project_id
    }
}

export const toggleDeleteProject = (project) => {
    return{
        type: TOGGLE_DELETE_PROJECT,
        payload: project
    }
}

export const toggleFromFavourite = (project_id, new_value) => (dispatch) => {

    axios.patch(`/api/projects/${project_id}`, {
        "isFavorite": new_value
    })
        .then(res => dispatch({ type: TOGGLE_PROJECT_FROM_FAVOURITE, payload: project_id}))
        .catch(error => console.log(error))

}

export const addNewProject = (project) => {
    return{
        type: ADD_NEW_PROJECT,
        payload: project
    }
}

/* SPREADSHEET */

export const setupActualProject = project_id => dispatch => {

    axios.get(`/api/files?projectId=${project_id}`)
        .then(res => {
            const data = { files: res.data.items, project_id }
            dispatch({ type: SETUP_ACTUAL_PROJECT, payload: data})
        })
        .catch(error => console.log(error))
}

export const setFileForRightPanel = (column, row, fileObject) => (dispatch, getState) => {

    const state = getState()

    axios.get(`/api/tags?projectId=${state.spreadsheet.project_id.toString()}&fileId=${fileObject.id}`)
        .then(res => {
            dispatch({ type: SET_FILE_FOR_RIGHT_PANEL, payload: { column, row, fileObject, tags: res.data.items } })
        })

}

export const closeRightPanel = () => {
    return{
        type: CLOSE_RIGHT_PANEL
    }
}

export const openViewer = () => {
    return{
        type: OPEN_VIEWER
    }
}

export const closeViewer = () => {
    return{
        type: CLOSE_VIEWER
    }
}

export const addTagToFile = tagObject => dispatch => {
    dispatch({ type: ADD_TAG_TO_FILE, payload: tagObject })
};

export const deleteTagFromFile = (tag_id)  => {
    console.log(tag_id)
    return{
        type: DELETE_TAG_FROM_FILE,
        payload: tag_id
    }
}

export const addFileToProject = (file) => {
    return{
        type: ADD_FILE_TO_PROJECT,
        payload: file
    }
}

export const markTagAsHighlighted = (tag) => {
    return{
        type: MARK_TAG_AS_HIGHLIGHTED,
        payload: tag
    }
}

// export const changeAppLanguage = (value) => ({
//     type: CHANGE_APP_LANGUAGE,
//     payload: value
// })