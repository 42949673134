import React from 'react';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faDoNotEnter } from '@fortawesome/pro-regular-svg-icons';

import '../styles/Notification.css'

export const NotifySuccess = (text) => {
    toast.success(
        <div style={{ display: "grid", gridTemplateColumns: "40px 1fr 40px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            justifySelf: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            color="#09822A"
            style={{ width: "24px" }}
          />
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "rgb(21, 41, 53)",
            }}
          >
              {text}
          </p>
        </div>
      </div>
    );
}

export const NotifyError = (text) => {
    toast.error(
        <div style={{ display: "grid", gridTemplateColumns: "40px 1fr 40px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            justifySelf: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faDoNotEnter}
            color="#D82829"
            style={{ width: "24px" }}
          />
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "rgb(21, 41, 53)",
            }}
          >
              {text}
          </p>
        </div>
      </div>
    );
}

export const NotifyInfo= (text) => {
    toast.info(
        <div style={{ display: "grid", gridTemplateColumns: "40px 1fr 40px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            justifySelf: "center",
            background: '#005075'
          }}
        >
          <FontAwesomeIcon
            icon={faInfo}
            color="#ffffff"
            style={{ width: "24px" }}
          />
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "rgb(21, 41, 53)",
            }}
          >
              {text}
          </p>
        </div>
      </div>
    );
}

export const NotifyWarning = (text) => {
    toast.warning(
        <div style={{ display: "grid", gridTemplateColumns: "40px 1fr 40px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            justifySelf: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faInfo}
            color="#FDB927"
            style={{ width: "24px" }}
          />
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "rgb(21, 41, 53)",
            }}
          >
              {text}
          </p>
        </div>
      </div>
    );
}
