import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import TsLogo from "../../images/LOGO_TEAMSYSTEM.png";
import "../../styles/TeamsystemPass.css";

const TSForgotPass = () => {
  return (
    <div>
      <div className="teamsystem_container">
        <div>
          <img src={TsLogo} alt="TS_Logo" width="240px" />
        </div>

        <div>
          <h2 className="ts_title">
            <small>Reimposta la password</small>
          </h2>
        </div>

        <div className="ts_linear_gradient"></div>

        <div>
          <input className="ts_email_input" placeholder="Indirizzo email" />
        </div>

        <div className="ts_linear_gradient"></div>

        <div className="ts_buttons_row">
          <button className="ts_green_button">
            <FontAwesomeIcon
              icon={faHome}
              color="#ffffff"
              style={{ width: "24px"}}
            />
            Inizio
          </button>
          <button className="ts_blue_button">Procedi</button>
        </div>
      </div>
    </div>
  );
};

export default TSForgotPass;
