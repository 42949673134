import {
    CHANGE_APP_LANGUAGE
} from "../actions/types";

export default (state = "", action) => {
    switch (action.type){
        case CHANGE_APP_LANGUAGE:
            return action.payload
        default:
            return state;
    }
}