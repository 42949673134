import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

import "../../styles/Login.css";
import "../../styles/successPass.css";

const PasswordChanged = () => {

    return (
        <div className="container">
            <div className="pass_div_container">
                <h2 className="main_title">Modifica la tua password</h2>
                <div className="text_icon_div">
                    <p className="light_subtitle">Complimenti, la password è stata modificata con successo!</p>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        color="#09822a"
                        style={{ width: "24px", textAlign: 'center' }}
                    />
                </div>
                <button className="blueButton">Torna alla pagina principale</button>
            </div>
        </div>
    )
}

export default PasswordChanged;