import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import "../../styles/Errors.css";
import errorImage from "../../images/404.png";
import teamsystemLogo from "../../images/LOGO_TEAMSYSTEM.png";

const Error404 = () => {
  let history = useHistory();

  React.useEffect(() => {
    errorDate();
  }, []);

  const [state, setState] = React.useState({
    today: "",
  });

  const errorDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    setState((state) => ({
      ...state,
      today: dd + "/" + mm + "/" + yyyy + " - " + time,
    }));
  };

  return (
    <div>
      <div className="top-navbar">
        <p className="logo">
          <span className="first-text">Data</span>
          <span className="second-text">Manager</span>
        </p>
      </div>

      <div className="error_page_wrapper">
        <div className="error_image_wrapper">
          <img className="error_401_image" src={errorImage} alt="error-404" />
        </div>
        <div className="error_texts_wrapper">
          <div className="teamsystem_logo_container">
            <img
              className="teamsystem_logo"
              src={teamsystemLogo}
              alt="teamsystem logo"
            />
          </div>

          <div className="error_text_container">
            <p className="error_text_title">
              Non troviamo la pagina che stai cercando…
            </p>
            <p className="error_text_time">
              Codice errore: 404 ({state.today})
            </p>
          </div>

          <div className="error_text_container">
            <p className="try_again_text">
              Riprova ad accedere più tardi. Se il problema persiste contatta{" "}
              <a href="#"> MySupport.</a>
            </p>
          </div>

          <div className="error_text_container">
            <button
              className="return_home_button"
              onClick={() => history.push("/")}
            >
              Torna alla pagina principale
            </button>
          </div>

          <div className="info_container">
            <FontAwesomeIcon icon={faLightbulbOn} className="light_on_icon" />
            <p className="info_title">Un consiglio utile </p>
            <p></p>
            <p className="info_details">
              Rispondendo ad alcune semplici domande, nella fase di redazione
              documenti, potrai compilare più facilmente alcuni documenti da
              depositare.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
