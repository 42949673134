import { useEffect, useRef, useState } from 'react';
import GC from '@grapecity/spread-sheets';
import { IO } from "@grapecity/spread-excelio";
import CircularProgress from '@material-ui/core/CircularProgress';

const ExcelViewer = ({ file }) => {
  const spreadsheetRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (file) {
      const excelIo = new IO();

      excelIo.open(file, (workbook) => {
        new GC.Spread.Sheets.Workbook(spreadsheetRef.current).fromJSON(workbook);
        setIsLoading(false)
      }, (error) => {
        console.error('Could not open file!');
      });
    }
  }, [file]);

  return <>
  
  {isLoading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%'}}><CircularProgress size={'4rem'} style={{color: '#009BD7'}}/></div>}
  <div ref={spreadsheetRef} style={{ width: '100%', height: '600px', visibility: isLoading ? 'hidden' : null }} />
  </>
}

export default ExcelViewer;
