import axios from "axios";
import { getParentData } from "./index";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const parentData = getParentData();

  const token = parentData.user.token;
  const itemid = parentData.item.id;
  if (token) {
    config.headers.Authorization = "Bearer " + token;
    config.headers["X-Item-ID"] = itemid;
  }
  return config;
});

export default axiosInstance;
