export const UPDATE_ACTIVE_LEFT_NAVBAR_OPTION = "UPDATE_ACTIVE_LEFT_NAVBAR_OPTION"
export const CHANGE_APP_LANGUAGE = "CHANGE_APP_LANGUAGE"
export const FETCH_ALL_PROJECTS_STARTED = "FETCH_ALL_PROJECTS_STARTED"
export const FETCH_ALL_PROJECTS_SUCCESS = "FETCH_ALL_PROJECTS_SUCCESS"
export const FETCH_ALL_PROJECTS_FAILURE = "FETCH_ALL_PROJECTS_FAILURE"
export const REMOVE_PROJECT_FROM_FAVOURITE = "REMOVE_PROJECT_FROM_FAVOURITE"
export const TOGGLE_PROJECT_FROM_FAVOURITE = "TOGGLE_PROJECT_FROM_FAVOURITE"
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const RENAME_PROJECT = "RENAME_PROJECT"
export const TOGGLE_ARCHIVE_PROJECT = "TOGGLE_ARCHIVE_PROJECT"
export const TOGGLE_DELETE_PROJECT = "TOGGLE_DELETE_PROJECT"

export const SETUP_ACTUAL_PROJECT = "SETUP_ACTUAL_PROJECT"
export const SET_FILE_FOR_RIGHT_PANEL = "SET_FILE_FOR_RIGHT_PANEL"
export const CLOSE_RIGHT_PANEL = "CLOSE_RIGHT_PANEL"
export const OPEN_VIEWER = "OPEN_VIEWER"
export const ADD_TAG_TO_FILE = "ADD_TAG_TO_FILE"
export const DELETE_TAG_FROM_FILE = "DELETE_TAG_FROM_FILE"
export const CLOSE_VIEWER = "CLOSE_VIEWER"
export const ADD_FILE_TO_PROJECT = "ADD_FILE_TO_PROJECT"
export const MARK_TAG_AS_HIGHLIGHTED = "MARK_TAG_AS_HIGHLIGHTED"